import { useForm, zodResolver } from '@mantine/form';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateItemCategoryRequest, usePostItemCategories } from '../../../api';
import { sanitizePayload } from '../../../utils';
import { Form } from './Form';
import { schema } from './schema';

export function New() {
  const navigate = useNavigate();

  const createItemCategoryMutation = usePostItemCategories({
    mutation: {
      onSuccess: () => navigate('..'),
      onError: console.error,
    },
  });

  const handleSubmit = useCallback(
    (values: CreateItemCategoryRequest) => {
      return createItemCategoryMutation.mutate({ data: values });
    },
    [createItemCategoryMutation.mutate],
  );

  const defaultValues = useMemo<CreateItemCategoryRequest>(() => ({ name: '', parent_id: '' }), []);

  const form = useForm<CreateItemCategoryRequest>({
    initialValues: defaultValues,
    validate: zodResolver(schema),
    validateInputOnBlur: true,
    transformValues: sanitizePayload,
  });

  return (
    <>
      <h1>New Item Category</h1>
      <Form
        onSubmit={handleSubmit}
        onError={console.error}
        loading={createItemCategoryMutation.isLoading}
        form={form}
      />
    </>
  );
}
