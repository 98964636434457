import { Route, Routes } from 'react-router-dom';
import { BusinessEntities } from './BusinessEntities';
import { Items } from './Items';

export function Catalogues() {
  return (
    <Routes>
      <Route path="business-entities/*" element={<BusinessEntities />} />
      <Route path="items/*" element={<Items />} />
    </Routes>
  );
}
