/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { UpdateUnitRequestDescription } from './updateUnitRequestDescription';

export interface UpdateUnitRequest {
  base: number;
  code: string;
  description?: UpdateUnitRequestDescription;
  exponent: number;
  name: string;
}
