/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { UnitDescription } from './unitDescription';

export interface Unit {
  base: number;
  code: string;
  /** Timestamp of when the inventory unit was created */
  created_at: string;
  description?: UnitDescription;
  exponent: number;
  /** Unique identifier of the unit */
  id: string;
  name: string;
  /** Timestamp of when the inventory unit was last updated */
  updated_at: string;
}
