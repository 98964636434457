/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  ItemCategory,
  GetItemCategoriesDefault,
  GetItemCategoriesParams,
  PostItemCategoriesDefault,
  CreateItemCategoryRequest,
  GetItemCategoriesIdDefault,
  PutItemCategoriesIdDefault,
  UpdateItemCategoryRequest,
  DeleteItemCategoriesIdDefault,
} from '.././model';
import { useCustomInstance } from '.././mutator/custom-instance';
import type { ErrorType } from '.././mutator/custom-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * List all item categories
 */
export const useGetItemCategoriesHook = () => {
  const getItemCategories = useCustomInstance<ItemCategory[]>();

  return (params?: GetItemCategoriesParams, signal?: AbortSignal) => {
    return getItemCategories({ url: `/item-categories/`, method: 'get', params, signal });
  };
};

export const getGetItemCategoriesQueryKey = (params?: GetItemCategoriesParams) =>
  [`/item-categories/`, ...(params ? [params] : [])] as const;

export const useGetItemCategoriesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetItemCategoriesHook>>>,
  TError = ErrorType<GetItemCategoriesDefault>,
>(
  params?: GetItemCategoriesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetItemCategoriesHook>>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetItemCategoriesHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetItemCategoriesQueryKey(params);

  const getItemCategories = useGetItemCategoriesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetItemCategoriesHook>>>
  > = ({ signal }) => getItemCategories(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetItemCategoriesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetItemCategoriesHook>>>
>;
export type GetItemCategoriesQueryError = ErrorType<GetItemCategoriesDefault>;

export const useGetItemCategories = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetItemCategoriesHook>>>,
  TError = ErrorType<GetItemCategoriesDefault>,
>(
  params?: GetItemCategoriesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetItemCategoriesHook>>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetItemCategoriesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create a new item category
 */
export const usePostItemCategoriesHook = () => {
  const postItemCategories = useCustomInstance<ItemCategory>();

  return (createItemCategoryRequest: CreateItemCategoryRequest) => {
    return postItemCategories({
      url: `/item-categories/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createItemCategoryRequest,
    });
  };
};

export const usePostItemCategoriesMutationOptions = <
  TError = ErrorType<PostItemCategoriesDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostItemCategoriesHook>>>,
    TError,
    { data: CreateItemCategoryRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostItemCategoriesHook>>>,
  TError,
  { data: CreateItemCategoryRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const postItemCategories = usePostItemCategoriesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostItemCategoriesHook>>>,
    { data: CreateItemCategoryRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postItemCategories(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostItemCategoriesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostItemCategoriesHook>>>
>;
export type PostItemCategoriesMutationBody = CreateItemCategoryRequest;
export type PostItemCategoriesMutationError = ErrorType<PostItemCategoriesDefault>;

export const usePostItemCategories = <
  TError = ErrorType<PostItemCategoriesDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostItemCategoriesHook>>>,
    TError,
    { data: CreateItemCategoryRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePostItemCategoriesMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get a single item category
 */
export const useGetItemCategoriesIdHook = () => {
  const getItemCategoriesId = useCustomInstance<ItemCategory>();

  return (id: string, signal?: AbortSignal) => {
    return getItemCategoriesId({ url: `/item-categories/${id}`, method: 'get', signal });
  };
};

export const getGetItemCategoriesIdQueryKey = (id: string) => [`/item-categories/${id}`] as const;

export const useGetItemCategoriesIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetItemCategoriesIdHook>>>,
  TError = ErrorType<GetItemCategoriesIdDefault>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetItemCategoriesIdHook>>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetItemCategoriesIdHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetItemCategoriesIdQueryKey(id);

  const getItemCategoriesId = useGetItemCategoriesIdHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetItemCategoriesIdHook>>>
  > = ({ signal }) => getItemCategoriesId(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetItemCategoriesIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetItemCategoriesIdHook>>>
>;
export type GetItemCategoriesIdQueryError = ErrorType<GetItemCategoriesIdDefault>;

export const useGetItemCategoriesId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetItemCategoriesIdHook>>>,
  TError = ErrorType<GetItemCategoriesIdDefault>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetItemCategoriesIdHook>>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetItemCategoriesIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update an item category
 */
export const usePutItemCategoriesIdHook = () => {
  const putItemCategoriesId = useCustomInstance<ItemCategory>();

  return (id: string, updateItemCategoryRequest: UpdateItemCategoryRequest) => {
    return putItemCategoriesId({
      url: `/item-categories/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateItemCategoryRequest,
    });
  };
};

export const usePutItemCategoriesIdMutationOptions = <
  TError = ErrorType<PutItemCategoriesIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutItemCategoriesIdHook>>>,
    TError,
    { id: string; data: UpdateItemCategoryRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePutItemCategoriesIdHook>>>,
  TError,
  { id: string; data: UpdateItemCategoryRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const putItemCategoriesId = usePutItemCategoriesIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePutItemCategoriesIdHook>>>,
    { id: string; data: UpdateItemCategoryRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return putItemCategoriesId(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutItemCategoriesIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePutItemCategoriesIdHook>>>
>;
export type PutItemCategoriesIdMutationBody = UpdateItemCategoryRequest;
export type PutItemCategoriesIdMutationError = ErrorType<PutItemCategoriesIdDefault>;

export const usePutItemCategoriesId = <
  TError = ErrorType<PutItemCategoriesIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutItemCategoriesIdHook>>>,
    TError,
    { id: string; data: UpdateItemCategoryRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePutItemCategoriesIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Delete an item category
 */
export const useDeleteItemCategoriesIdHook = () => {
  const deleteItemCategoriesId = useCustomInstance<ItemCategory>();

  return (id: string) => {
    return deleteItemCategoriesId({ url: `/item-categories/${id}`, method: 'delete' });
  };
};

export const useDeleteItemCategoriesIdMutationOptions = <
  TError = ErrorType<DeleteItemCategoriesIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteItemCategoriesIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteItemCategoriesIdHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteItemCategoriesId = useDeleteItemCategoriesIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteItemCategoriesIdHook>>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteItemCategoriesId(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteItemCategoriesIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteItemCategoriesIdHook>>>
>;

export type DeleteItemCategoriesIdMutationError = ErrorType<DeleteItemCategoriesIdDefault>;

export const useDeleteItemCategoriesId = <
  TError = ErrorType<DeleteItemCategoriesIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteItemCategoriesIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteItemCategoriesIdMutationOptions(options);

  return useMutation(mutationOptions);
};
