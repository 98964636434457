/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */

export type BusinessEntitiesOrderBy =
  (typeof BusinessEntitiesOrderBy)[keyof typeof BusinessEntitiesOrderBy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessEntitiesOrderBy = {
  id: 'id',
  created_at: 'created_at',
  name: 'name',
} as const;
