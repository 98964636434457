/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { ItemCategoriesFindFilterName } from './itemCategoriesFindFilterName';
import type { ItemCategoriesFindFilterParentId } from './itemCategoriesFindFilterParentId';

/**
 * The filter to use when searching for item_categories
 */
export interface ItemCategoriesFindFilter {
  /** The name of the item_category to search for */
  name?: ItemCategoriesFindFilterName;
  /** The parent id of the item category */
  parent_id?: ItemCategoriesFindFilterParentId;
}
