/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { InventoryMovementsPaginationOrderBy } from './inventoryMovementsPaginationOrderBy';
import type { InventoryMovementsPaginationOrderDirection } from './inventoryMovementsPaginationOrderDirection';
import type { InventoryMovementsPaginationPage } from './inventoryMovementsPaginationPage';
import type { InventoryMovementsPaginationPerPage } from './inventoryMovementsPaginationPerPage';

export interface InventoryMovementsPagination {
  /** The field to order by. Defaults to `id` if not specified. */
  order_by?: InventoryMovementsPaginationOrderBy;
  /** The direction to order by. Defaults to `asc` if not specified. */
  order_direction?: InventoryMovementsPaginationOrderDirection;
  /** The page number to return. Defaults to 1 if not specified. */
  page?: InventoryMovementsPaginationPage;
  /** The number of items per page. Defaults to 10 if not specified. */
  per_page?: InventoryMovementsPaginationPerPage;
}
