/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { UpdateInventoryMovementEntryRequestAmount } from './updateInventoryMovementEntryRequestAmount';
import type { UpdateInventoryMovementEntryRequestDeliveredAt } from './updateInventoryMovementEntryRequestDeliveredAt';
import type { UpdateInventoryMovementEntryRequestSentAt } from './updateInventoryMovementEntryRequestSentAt';

export interface UpdateInventoryMovementEntryRequest {
  /** Amount of the inventory item */
  amount: UpdateInventoryMovementEntryRequestAmount;
  /** When this entry was delivered */
  delivered_at?: UpdateInventoryMovementEntryRequestDeliveredAt;
  /** ID of the inventory item */
  inventory_item_id: string;
  /** ID of the inventory movement document */
  inventory_movement_id: string;
  /** When this entry was sent */
  sent_at?: UpdateInventoryMovementEntryRequestSentAt;
}
