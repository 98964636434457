import { useForm, zodResolver } from '@mantine/form';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateItemRequest, usePostItems } from '../../../api';
import { sanitizePayload } from '../../../utils';
import { Form } from './Form';
import { schema } from './schema';

export function New() {
  const navigate = useNavigate();

  const itemMutation = usePostItems({
    mutation: {
      onSuccess: () => navigate('..'),
      onError: console.error,
    },
  });

  const handleSubmit = useCallback(
    (values: CreateItemRequest) => itemMutation.mutate({ data: values }),
    [itemMutation.mutate],
  );

  const defaultValues = useMemo<CreateItemRequest>(
    () => ({
      name: '',
      item_category_id: '',
      description: '',
      can_be_purchased: false,
      can_be_sold: false,
      has_inventory: false,
      has_lot_number: false,
      has_serial_number: false,
      is_input: false,
      is_perishable: false,
      is_product: false,
      is_raw_material: false,
      is_service: false,
    }),
    [],
  );

  const form = useForm<CreateItemRequest>({
    initialValues: defaultValues,
    validate: zodResolver(schema),
    validateInputOnBlur: true,
    transformValues: sanitizePayload,
  });

  return (
    <>
      <h1>New Item</h1>
      <Form
        onSubmit={handleSubmit}
        onError={console.error}
        loading={itemMutation.isLoading}
        form={form}
      />
    </>
  );
}
