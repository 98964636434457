/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  FinanceDocument,
  GetFinanceDocumentsDefault,
  GetFinanceDocumentsParams,
  PostFinanceDocumentsDefault,
  CreateFinanceDocumentRequest,
  GetFinanceDocumentsIdDefault,
  PutFinanceDocumentsIdDefault,
  UpdateFinanceDocumentRequest,
  DeleteFinanceDocumentsIdDefault,
} from '.././model';
import { useCustomInstance } from '.././mutator/custom-instance';
import type { ErrorType } from '.././mutator/custom-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * List all finance documents
 */
export const useGetFinanceDocumentsHook = () => {
  const getFinanceDocuments = useCustomInstance<FinanceDocument[]>();

  return (params?: GetFinanceDocumentsParams, signal?: AbortSignal) => {
    return getFinanceDocuments({ url: `/finance-documents/`, method: 'get', params, signal });
  };
};

export const getGetFinanceDocumentsQueryKey = (params?: GetFinanceDocumentsParams) =>
  [`/finance-documents/`, ...(params ? [params] : [])] as const;

export const useGetFinanceDocumentsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentsHook>>>,
  TError = ErrorType<GetFinanceDocumentsDefault>,
>(
  params?: GetFinanceDocumentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentsHook>>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFinanceDocumentsQueryKey(params);

  const getFinanceDocuments = useGetFinanceDocumentsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentsHook>>>
  > = ({ signal }) => getFinanceDocuments(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetFinanceDocumentsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentsHook>>>
>;
export type GetFinanceDocumentsQueryError = ErrorType<GetFinanceDocumentsDefault>;

export const useGetFinanceDocuments = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentsHook>>>,
  TError = ErrorType<GetFinanceDocumentsDefault>,
>(
  params?: GetFinanceDocumentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentsHook>>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetFinanceDocumentsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create a new finance document
 */
export const usePostFinanceDocumentsHook = () => {
  const postFinanceDocuments = useCustomInstance<FinanceDocument>();

  return (createFinanceDocumentRequest: CreateFinanceDocumentRequest) => {
    return postFinanceDocuments({
      url: `/finance-documents/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createFinanceDocumentRequest,
    });
  };
};

export const usePostFinanceDocumentsMutationOptions = <
  TError = ErrorType<PostFinanceDocumentsDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostFinanceDocumentsHook>>>,
    TError,
    { data: CreateFinanceDocumentRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostFinanceDocumentsHook>>>,
  TError,
  { data: CreateFinanceDocumentRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const postFinanceDocuments = usePostFinanceDocumentsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostFinanceDocumentsHook>>>,
    { data: CreateFinanceDocumentRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postFinanceDocuments(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostFinanceDocumentsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostFinanceDocumentsHook>>>
>;
export type PostFinanceDocumentsMutationBody = CreateFinanceDocumentRequest;
export type PostFinanceDocumentsMutationError = ErrorType<PostFinanceDocumentsDefault>;

export const usePostFinanceDocuments = <
  TError = ErrorType<PostFinanceDocumentsDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostFinanceDocumentsHook>>>,
    TError,
    { data: CreateFinanceDocumentRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePostFinanceDocumentsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get a single finance document
 */
export const useGetFinanceDocumentsIdHook = () => {
  const getFinanceDocumentsId = useCustomInstance<FinanceDocument>();

  return (id: string, signal?: AbortSignal) => {
    return getFinanceDocumentsId({ url: `/finance-documents/${id}`, method: 'get', signal });
  };
};

export const getGetFinanceDocumentsIdQueryKey = (id: string) =>
  [`/finance-documents/${id}`] as const;

export const useGetFinanceDocumentsIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentsIdHook>>>,
  TError = ErrorType<GetFinanceDocumentsIdDefault>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentsIdHook>>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentsIdHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFinanceDocumentsIdQueryKey(id);

  const getFinanceDocumentsId = useGetFinanceDocumentsIdHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentsIdHook>>>
  > = ({ signal }) => getFinanceDocumentsId(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetFinanceDocumentsIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentsIdHook>>>
>;
export type GetFinanceDocumentsIdQueryError = ErrorType<GetFinanceDocumentsIdDefault>;

export const useGetFinanceDocumentsId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentsIdHook>>>,
  TError = ErrorType<GetFinanceDocumentsIdDefault>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentsIdHook>>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetFinanceDocumentsIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update a finance document
 */
export const usePutFinanceDocumentsIdHook = () => {
  const putFinanceDocumentsId = useCustomInstance<FinanceDocument>();

  return (id: string, updateFinanceDocumentRequest: UpdateFinanceDocumentRequest) => {
    return putFinanceDocumentsId({
      url: `/finance-documents/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateFinanceDocumentRequest,
    });
  };
};

export const usePutFinanceDocumentsIdMutationOptions = <
  TError = ErrorType<PutFinanceDocumentsIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutFinanceDocumentsIdHook>>>,
    TError,
    { id: string; data: UpdateFinanceDocumentRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePutFinanceDocumentsIdHook>>>,
  TError,
  { id: string; data: UpdateFinanceDocumentRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const putFinanceDocumentsId = usePutFinanceDocumentsIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePutFinanceDocumentsIdHook>>>,
    { id: string; data: UpdateFinanceDocumentRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return putFinanceDocumentsId(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutFinanceDocumentsIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePutFinanceDocumentsIdHook>>>
>;
export type PutFinanceDocumentsIdMutationBody = UpdateFinanceDocumentRequest;
export type PutFinanceDocumentsIdMutationError = ErrorType<PutFinanceDocumentsIdDefault>;

export const usePutFinanceDocumentsId = <
  TError = ErrorType<PutFinanceDocumentsIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutFinanceDocumentsIdHook>>>,
    TError,
    { id: string; data: UpdateFinanceDocumentRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePutFinanceDocumentsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Delete a finance document
 */
export const useDeleteFinanceDocumentsIdHook = () => {
  const deleteFinanceDocumentsId = useCustomInstance<FinanceDocument>();

  return (id: string) => {
    return deleteFinanceDocumentsId({ url: `/finance-documents/${id}`, method: 'delete' });
  };
};

export const useDeleteFinanceDocumentsIdMutationOptions = <
  TError = ErrorType<DeleteFinanceDocumentsIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteFinanceDocumentsIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteFinanceDocumentsIdHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteFinanceDocumentsId = useDeleteFinanceDocumentsIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteFinanceDocumentsIdHook>>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteFinanceDocumentsId(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteFinanceDocumentsIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteFinanceDocumentsIdHook>>>
>;

export type DeleteFinanceDocumentsIdMutationError = ErrorType<DeleteFinanceDocumentsIdDefault>;

export const useDeleteFinanceDocumentsId = <
  TError = ErrorType<DeleteFinanceDocumentsIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteFinanceDocumentsIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteFinanceDocumentsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
