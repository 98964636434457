/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { CreateInventoryMovementEntryRequestAmount } from './createInventoryMovementEntryRequestAmount';
import type { CreateInventoryMovementEntryRequestDeliveredAt } from './createInventoryMovementEntryRequestDeliveredAt';
import type { CreateInventoryMovementEntryRequestSentAt } from './createInventoryMovementEntryRequestSentAt';

export interface CreateInventoryMovementEntryRequest {
  /** Amount of the inventory item */
  amount: CreateInventoryMovementEntryRequestAmount;
  /** When this entry was delivered */
  delivered_at?: CreateInventoryMovementEntryRequestDeliveredAt;
  /** ID of the inventory item */
  inventory_item_id: string;
  /** ID of the inventory movement document */
  inventory_movement_id: string;
  /** When this entry was sent */
  sent_at?: CreateInventoryMovementEntryRequestSentAt;
}
