/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { UnitsOrderBy } from './unitsOrderBy';
import type { UnitsOrderDirection } from './unitsOrderDirection';

export type GetUnitsParams = {
  order_by?: UnitsOrderBy | null;
  order_direction?: UnitsOrderDirection | null;
  page?: number | null;
  per_page?: number | null;
  can_be_purchased?: boolean | null;
  can_be_sold?: boolean | null;
  has_inventory?: boolean | null;
  has_lot_number?: boolean | null;
  has_serial_number?: boolean | null;
  is_input?: boolean | null;
  is_perishable?: boolean | null;
  is_product?: boolean | null;
  is_raw_material?: boolean | null;
  is_service?: boolean | null;
  name?: string | null;
  unit_category_id?: string | null;
};
