/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { InventoryMovementEntryAmount } from './inventoryMovementEntryAmount';
import type { InventoryMovementEntryDeliveredAt } from './inventoryMovementEntryDeliveredAt';
import type { InventoryMovementEntrySentAt } from './inventoryMovementEntrySentAt';

export interface InventoryMovementEntry {
  /** Amount of the inventory item */
  amount: InventoryMovementEntryAmount;
  /** Timestamp of when the inventory movement entry was created */
  created_at: string;
  /** When this entry was delivered */
  delivered_at?: InventoryMovementEntryDeliveredAt;
  /** Unique identifier */
  id: string;
  /** ID of the inventory item */
  inventory_item_id: string;
  /** ID of the inventory movement document */
  inventory_movement_id: string;
  /** When this entry was sent */
  sent_at?: InventoryMovementEntrySentAt;
  /** Timestamp of when the inventory movement entry was last updated */
  updated_at: string;
}
