import React from 'react';
import { Navbar } from '@mantine/core';
import { User } from './User';
import { MainLinks } from './MainLinks';

interface Props {
  hidden: boolean;
}

export function Nav(props: Props) {
  return (
    <Navbar p="md" hiddenBreakpoint="md" hidden={props.hidden} width={{ base: 300 }}>
      <Navbar.Section grow mt="md">
        <MainLinks />
      </Navbar.Section>
      <Navbar.Section>
        <User />
      </Navbar.Section>
    </Navbar>
  );
}
