/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { CreateInventoryMovementRequestConcept } from './createInventoryMovementRequestConcept';
import type { CreateInventoryMovementRequestConditions } from './createInventoryMovementRequestConditions';
import type { CreateInventoryMovementRequestDescription } from './createInventoryMovementRequestDescription';
import type { CreateInventoryMovementRequestInsurance } from './createInventoryMovementRequestInsurance';
import type { CreateInventoryMovementRequestReference } from './createInventoryMovementRequestReference';
import type { CreateInventoryMovementRequestTerms } from './createInventoryMovementRequestTerms';
import type { CreateInventoryMovementRequestTransport } from './createInventoryMovementRequestTransport';

export interface CreateInventoryMovementRequest {
  /** A concept */
  concept?: CreateInventoryMovementRequestConcept;
  /** Delivery conditions */
  conditions?: CreateInventoryMovementRequestConditions;
  /** A short description */
  description?: CreateInventoryMovementRequestDescription;
  /** ID of the destination business entity */
  destination_business_entity_id: string;
  /** The insurance details */
  insurance?: CreateInventoryMovementRequestInsurance;
  /** ID of the origin business entity */
  origin_business_entity_id: string;
  /** A reference code */
  reference?: CreateInventoryMovementRequestReference;
  /** Delivery terms */
  terms?: CreateInventoryMovementRequestTerms;
  /** The transport details */
  transport?: CreateInventoryMovementRequestTransport;
}
