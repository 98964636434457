/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { InventoryItemBarcode } from './inventoryItemBarcode';
import type { InventoryItemDefaultDeliveryWarehouseId } from './inventoryItemDefaultDeliveryWarehouseId';
import type { InventoryItemDefaultReceptionWarehouseId } from './inventoryItemDefaultReceptionWarehouseId';
import type { InventoryItemSku } from './inventoryItemSku';
import type { InventoryItemSupplierId } from './inventoryItemSupplierId';
import type { InventoryItemUnitId } from './inventoryItemUnitId';

export interface InventoryItem {
  /** Barcode of the inventory item */
  barcode?: InventoryItemBarcode;
  /** Timestamp of when the inventory item was created */
  created_at: string;
  /** Identifier of the business entity that is the default delivery warehouse of this inventory item */
  default_delivery_warehouse_id?: InventoryItemDefaultDeliveryWarehouseId;
  /** Identifier of the business entity that is the default reception warehouse of this inventory item */
  default_reception_warehouse_id?: InventoryItemDefaultReceptionWarehouseId;
  /** Unique identifier of the inventory item */
  id: string;
  /** Identifier of the item this inventory item is for */
  item_id: string;
  /** Stock keeping unit of the inventory item */
  sku?: InventoryItemSku;
  /** Identifier of the business entity that is the supplier of this inventory item */
  supplier_id?: InventoryItemSupplierId;
  /** Identifier of the unit this inventory item is for */
  unit_id?: InventoryItemUnitId;
  /** Timestamp of when the inventory item was last updated */
  updated_at: string;
}
