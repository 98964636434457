/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { BusinessEntitiesPaginationOrderBy } from './businessEntitiesPaginationOrderBy';
import type { BusinessEntitiesPaginationOrderDirection } from './businessEntitiesPaginationOrderDirection';
import type { BusinessEntitiesPaginationPage } from './businessEntitiesPaginationPage';
import type { BusinessEntitiesPaginationPerPage } from './businessEntitiesPaginationPerPage';

export interface BusinessEntitiesPagination {
  /** The field to order by. Defaults to `id` if not specified. */
  order_by?: BusinessEntitiesPaginationOrderBy;
  /** The direction to order by. Defaults to `asc` if not specified. */
  order_direction?: BusinessEntitiesPaginationOrderDirection;
  /** The page number to return. Defaults to 1 if not specified. */
  page?: BusinessEntitiesPaginationPage;
  /** The number of items per page. Defaults to 10 if not specified. */
  per_page?: BusinessEntitiesPaginationPerPage;
}
