/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */

/**
 * A default error response for most API errors.
 */
export type DeleteInventoryItemsIdDefault = {
  /** An error message. */
  error: string;
  /** Optional Additional error details. */
  error_details?: unknown;
  /** A unique error ID. */
  error_id: string;
};
