/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { FinanceDocumentsOrderBy } from './financeDocumentsOrderBy';
import type { FinanceDocumentsOrderDirection } from './financeDocumentsOrderDirection';

export type GetFinanceDocumentsParams = {
  /**
   * The field to order by. Defaults to `id` if not specified.
   */
  order_by?: FinanceDocumentsOrderBy | null;
  /**
   * The direction to order by. Defaults to `asc` if not specified.
   */
  order_direction?: FinanceDocumentsOrderDirection | null;
  /**
   * The page number to return. Defaults to 1 if not specified.
   */
  page?: number | null;
  /**
   * The number of items per page. Defaults to 10 if not specified.
   */
  per_page?: number | null;
  /**
   * The date of the finance document to search for
   */
  date?: string | null;
  /**
   * The document type of the finance document to search for
   */
  document_type?: string | null;
  /**
   * The status of the finance document to search for
   */
  status?: string | null;
};
