/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { InventoryLevelLeadTimeAverageAnyOf } from './inventoryLevelLeadTimeAverageAnyOf';

/**
 * The average lead time of the inventory item
 */
export type InventoryLevelLeadTimeAverage = InventoryLevelLeadTimeAverageAnyOf | null;
