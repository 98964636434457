import {
  Button,
  Checkbox,
  Group,
  LoadingOverlay,
  Select,
  Textarea,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { FormErrors } from '@mantine/form/lib/types';
import { useGetItemCategories } from '../../../api';

interface Props<T> {
  onSubmit: (values: T) => void;
  onError: (errors: FormErrors, values: T) => void;
  loading: boolean;
  form: UseFormReturnType<T>;
}

export function Form<T>({ form, onSubmit, onError, loading }: Props<T>) {
  const itemCategories = useGetItemCategories();

  return (
    <form onSubmit={form.onSubmit(onSubmit, onError)}>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <TextInput
        withAsterisk
        label="Name"
        description={'Name of this item.'}
        placeholder="name"
        {...form.getInputProps('name')}
      />
      <Textarea
        mt="md"
        label="Description"
        description={'Describe this item.'}
        placeholder="Autosize with no rows limit"
        autosize
        minRows={2}
        {...form.getInputProps('description')}
      />
      <Select
        mt="md"
        label="Category"
        required={false}
        searchable
        clearable
        allowDeselect
        disabled={itemCategories.isLoading}
        data={
          itemCategories.data?.map((itemCategory) => ({
            value: itemCategory.id,
            label: itemCategory.name,
          })) ?? ['Loading...']
        }
        {...form.getInputProps('item_category_id')}
      />
      <Checkbox
        mt={'md'}
        label="Can be purchased"
        description={'This item can be purchased from a supplier.'}
        {...form.getInputProps('can_be_purchased', { type: 'input' })}
      />
      <Checkbox
        mt={'md'}
        label="Can be sold"
        description={'This item can be sold to a customer.'}
        {...form.getInputProps('can_be_sold', { type: 'checkbox' })}
      />
      <Checkbox
        mt={'md'}
        label="Has Inventory"
        description={'This item is tracked in inventory.'}
        {...form.getInputProps('has_inventory', { type: 'checkbox' })}
      />
      <Checkbox
        mt={'md'}
        label="Has lot number"
        description={'This item has a lot number.'}
        {...form.getInputProps('has_lot_number', { type: 'checkbox' })}
      />
      <Checkbox
        mt={'md'}
        label="Has serial number"
        description={'This item has a serial number.'}
        {...form.getInputProps('has_serial_number', {
          type: 'checkbox',
        })}
      />
      <Checkbox
        mt={'md'}
        label="Is input"
        description={'This item is an input in a production process.'}
        {...form.getInputProps('is_input', { type: 'checkbox' })}
      />
      <Checkbox
        mt={'md'}
        label="Is perishable"
        description={'We need to track the expiration date of this item.'}
        {...form.getInputProps('is_perishable', { type: 'checkbox' })}
      />
      <Checkbox
        mt={'md'}
        label="Is product"
        description={'This item is a finished product.'}
        {...form.getInputProps('is_product', { type: 'checkbox' })}
      />
      <Checkbox
        mt={'md'}
        label="Is raw material"
        description={'This item is a raw material.'}
        {...form.getInputProps('is_raw_material', { type: 'checkbox' })}
      />
      <Checkbox
        mt={'md'}
        label="Is service"
        description={'This item is a service and therefore does not consume inventory.'}
        {...form.getInputProps('is_service', { type: 'checkbox' })}
      />
      <Group position="right" mt="md">
        <Button type="button">Cancel</Button>
        <Button type="submit">Submit</Button>
      </Group>
    </form>
  );
}
