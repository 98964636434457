import { Button, Group, LoadingOverlay, Modal } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { isNull, omit, omitBy, pipe } from 'lodash/fp';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  UpdateInventoryItemRequest,
  useDeleteInventoryItemsId,
  useGetInventoryItemsId,
  usePutInventoryItemsId,
} from '../../../api';
import { sanitizePayload } from '../../../utils';
import { Form } from './Form';
import { schema } from './schema';

export function Edit() {
  const [showDelete, { open, close }] = useDisclosure(false);
  const navigate = useNavigate();
  const { inventoryItemId } = useParams();

  const updateInventoryItemMutation = usePutInventoryItemsId({
    mutation: {
      onSuccess: () => navigate('..'),
      onError: console.error,
    },
  });

  const deleteInventoryItemMutation = useDeleteInventoryItemsId({
    mutation: {
      onSuccess: () => navigate('..'),
      onError: console.error,
    },
  });

  const handleSubmit = useCallback(
    (values: UpdateInventoryItemRequest) => {
      if (!inventoryItemId) return;

      console.log('values', values);
      updateInventoryItemMutation.mutate({ id: inventoryItemId, data: values });
    },
    [updateInventoryItemMutation.mutate],
  );

  const defaultValues = useMemo<UpdateInventoryItemRequest>(
    () => ({
      barcode: '',
      default_delivery_warehouse_id: '',
      default_reception_warehouse_id: '',
      item_id: '',
      sku: '',
      supplier_id: '',
      unit_id: '',
    }),
    [],
  );

  const form = useForm<UpdateInventoryItemRequest>({
    initialValues: defaultValues,
    validate: zodResolver(schema),
    validateInputOnBlur: true,
    transformValues: sanitizePayload,
  });

  const inventoryItem = useGetInventoryItemsId(String(inventoryItemId), {
    query: {
      enabled: Boolean(inventoryItemId),
      onSuccess: (inventoryItem) => {
        const values = pipe(
          omit(['id', 'created_at', 'updated_at']),
          omitBy(isNull),
        )(inventoryItem);

        form.setValues(values);
        form.resetDirty();
      },
    },
  });

  if (inventoryItem.isLoading || !inventoryItem.data) {
    return <LoadingOverlay visible={true} />;
  }

  return (
    <>
      <Modal opened={showDelete} onClose={close} title="Confirm Delete">
        <p>Are you sure you want to delete {inventoryItem.data.sku}?</p>
        <Button
          variant={'outline'}
          color={'red'}
          onClick={() => {
            if (!inventoryItemId) return;
            deleteInventoryItemMutation.mutate({ id: inventoryItemId });
          }}
        >
          Confirm
        </Button>
      </Modal>

      <h1>Edit {inventoryItem.data.sku}</h1>
      <Form
        onSubmit={handleSubmit}
        onError={console.error}
        loading={updateInventoryItemMutation.isLoading}
        form={form}
      />
      <Group position="right" mt="md">
        <Button variant={'outline'} color={'red'} onClick={open}>
          Delete
        </Button>
      </Group>
    </>
  );
}
