/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */

export type ItemsOrderBy = (typeof ItemsOrderBy)[keyof typeof ItemsOrderBy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ItemsOrderBy = {
  id: 'id',
  created_at: 'created_at',
  name: 'name',
  item_category_id: 'item_category_id',
} as const;
