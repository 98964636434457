/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  InventoryItem,
  GetInventoryItemsDefault,
  GetInventoryItemsParams,
  PostInventoryItemsDefault,
  CreateInventoryItemRequest,
  GetInventoryItemsIdDefault,
  PutInventoryItemsIdDefault,
  UpdateInventoryItemRequest,
  DeleteInventoryItemsIdDefault,
} from '.././model';
import { useCustomInstance } from '.././mutator/custom-instance';
import type { ErrorType } from '.././mutator/custom-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * List all inventory items
 */
export const useGetInventoryItemsHook = () => {
  const getInventoryItems = useCustomInstance<InventoryItem[]>();

  return (params?: GetInventoryItemsParams, signal?: AbortSignal) => {
    return getInventoryItems({ url: `/inventory-items/`, method: 'get', params, signal });
  };
};

export const getGetInventoryItemsQueryKey = (params?: GetInventoryItemsParams) =>
  [`/inventory-items/`, ...(params ? [params] : [])] as const;

export const useGetInventoryItemsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInventoryItemsHook>>>,
  TError = ErrorType<GetInventoryItemsDefault>,
>(
  params?: GetInventoryItemsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetInventoryItemsHook>>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetInventoryItemsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInventoryItemsQueryKey(params);

  const getInventoryItems = useGetInventoryItemsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetInventoryItemsHook>>>
  > = ({ signal }) => getInventoryItems(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetInventoryItemsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetInventoryItemsHook>>>
>;
export type GetInventoryItemsQueryError = ErrorType<GetInventoryItemsDefault>;

export const useGetInventoryItems = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInventoryItemsHook>>>,
  TError = ErrorType<GetInventoryItemsDefault>,
>(
  params?: GetInventoryItemsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetInventoryItemsHook>>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetInventoryItemsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create a new inventory item
 */
export const usePostInventoryItemsHook = () => {
  const postInventoryItems = useCustomInstance<InventoryItem>();

  return (createInventoryItemRequest: CreateInventoryItemRequest) => {
    return postInventoryItems({
      url: `/inventory-items/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createInventoryItemRequest,
    });
  };
};

export const usePostInventoryItemsMutationOptions = <
  TError = ErrorType<PostInventoryItemsDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostInventoryItemsHook>>>,
    TError,
    { data: CreateInventoryItemRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostInventoryItemsHook>>>,
  TError,
  { data: CreateInventoryItemRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const postInventoryItems = usePostInventoryItemsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostInventoryItemsHook>>>,
    { data: CreateInventoryItemRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postInventoryItems(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostInventoryItemsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostInventoryItemsHook>>>
>;
export type PostInventoryItemsMutationBody = CreateInventoryItemRequest;
export type PostInventoryItemsMutationError = ErrorType<PostInventoryItemsDefault>;

export const usePostInventoryItems = <
  TError = ErrorType<PostInventoryItemsDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostInventoryItemsHook>>>,
    TError,
    { data: CreateInventoryItemRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePostInventoryItemsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get a single inventory item
 */
export const useGetInventoryItemsIdHook = () => {
  const getInventoryItemsId = useCustomInstance<InventoryItem>();

  return (id: string, signal?: AbortSignal) => {
    return getInventoryItemsId({ url: `/inventory-items/${id}`, method: 'get', signal });
  };
};

export const getGetInventoryItemsIdQueryKey = (id: string) => [`/inventory-items/${id}`] as const;

export const useGetInventoryItemsIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInventoryItemsIdHook>>>,
  TError = ErrorType<GetInventoryItemsIdDefault>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetInventoryItemsIdHook>>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetInventoryItemsIdHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInventoryItemsIdQueryKey(id);

  const getInventoryItemsId = useGetInventoryItemsIdHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetInventoryItemsIdHook>>>
  > = ({ signal }) => getInventoryItemsId(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetInventoryItemsIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetInventoryItemsIdHook>>>
>;
export type GetInventoryItemsIdQueryError = ErrorType<GetInventoryItemsIdDefault>;

export const useGetInventoryItemsId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInventoryItemsIdHook>>>,
  TError = ErrorType<GetInventoryItemsIdDefault>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetInventoryItemsIdHook>>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetInventoryItemsIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update an inventory item
 */
export const usePutInventoryItemsIdHook = () => {
  const putInventoryItemsId = useCustomInstance<InventoryItem>();

  return (id: string, updateInventoryItemRequest: UpdateInventoryItemRequest) => {
    return putInventoryItemsId({
      url: `/inventory-items/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateInventoryItemRequest,
    });
  };
};

export const usePutInventoryItemsIdMutationOptions = <
  TError = ErrorType<PutInventoryItemsIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutInventoryItemsIdHook>>>,
    TError,
    { id: string; data: UpdateInventoryItemRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePutInventoryItemsIdHook>>>,
  TError,
  { id: string; data: UpdateInventoryItemRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const putInventoryItemsId = usePutInventoryItemsIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePutInventoryItemsIdHook>>>,
    { id: string; data: UpdateInventoryItemRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return putInventoryItemsId(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutInventoryItemsIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePutInventoryItemsIdHook>>>
>;
export type PutInventoryItemsIdMutationBody = UpdateInventoryItemRequest;
export type PutInventoryItemsIdMutationError = ErrorType<PutInventoryItemsIdDefault>;

export const usePutInventoryItemsId = <
  TError = ErrorType<PutInventoryItemsIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutInventoryItemsIdHook>>>,
    TError,
    { id: string; data: UpdateInventoryItemRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePutInventoryItemsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Delete an inventory item
 */
export const useDeleteInventoryItemsIdHook = () => {
  const deleteInventoryItemsId = useCustomInstance<InventoryItem>();

  return (id: string) => {
    return deleteInventoryItemsId({ url: `/inventory-items/${id}`, method: 'delete' });
  };
};

export const useDeleteInventoryItemsIdMutationOptions = <
  TError = ErrorType<DeleteInventoryItemsIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteInventoryItemsIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteInventoryItemsIdHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteInventoryItemsId = useDeleteInventoryItemsIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteInventoryItemsIdHook>>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteInventoryItemsId(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteInventoryItemsIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteInventoryItemsIdHook>>>
>;

export type DeleteInventoryItemsIdMutationError = ErrorType<DeleteInventoryItemsIdDefault>;

export const useDeleteInventoryItemsId = <
  TError = ErrorType<DeleteInventoryItemsIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteInventoryItemsIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteInventoryItemsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
