/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { ItemsFilterCanBePurchased } from './itemsFilterCanBePurchased';
import type { ItemsFilterCanBeSold } from './itemsFilterCanBeSold';
import type { ItemsFilterHasInventory } from './itemsFilterHasInventory';
import type { ItemsFilterHasLotNumber } from './itemsFilterHasLotNumber';
import type { ItemsFilterHasSerialNumber } from './itemsFilterHasSerialNumber';
import type { ItemsFilterIsInput } from './itemsFilterIsInput';
import type { ItemsFilterIsPerishable } from './itemsFilterIsPerishable';
import type { ItemsFilterIsProduct } from './itemsFilterIsProduct';
import type { ItemsFilterIsRawMaterial } from './itemsFilterIsRawMaterial';
import type { ItemsFilterIsService } from './itemsFilterIsService';
import type { ItemsFilterItemCategoryId } from './itemsFilterItemCategoryId';
import type { ItemsFilterName } from './itemsFilterName';

export interface ItemsFilter {
  can_be_purchased?: ItemsFilterCanBePurchased;
  can_be_sold?: ItemsFilterCanBeSold;
  has_inventory?: ItemsFilterHasInventory;
  has_lot_number?: ItemsFilterHasLotNumber;
  has_serial_number?: ItemsFilterHasSerialNumber;
  is_input?: ItemsFilterIsInput;
  is_perishable?: ItemsFilterIsPerishable;
  is_product?: ItemsFilterIsProduct;
  is_raw_material?: ItemsFilterIsRawMaterial;
  is_service?: ItemsFilterIsService;
  item_category_id?: ItemsFilterItemCategoryId;
  name?: ItemsFilterName;
}
