import {
  Button,
  Checkbox,
  Divider,
  Group,
  LoadingOverlay,
  NumberInput,
  Select,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { FormErrors } from '@mantine/form/lib/types';
import {
  CreateBusinessEntityRequest,
  UpdateBusinessEntityRequest,
  useGetBusinessEntities,
} from '../../../api';

export type Values = Partial<CreateBusinessEntityRequest> | Partial<UpdateBusinessEntityRequest>;

interface Props<T> {
  onSubmit: (values: T) => void;
  onError: (errors: FormErrors, values: T) => void;
  loading: boolean;
  form: UseFormReturnType<T>;
}

export function Form<T extends Values>({ form, onSubmit, onError, loading }: Props<T>) {
  const contacts = useGetBusinessEntities({ is_contact: true });

  return (
    <form onSubmit={form.onSubmit(onSubmit, onError)}>
      <LoadingOverlay visible={loading} overlayBlur={2} />

      <TextInput
        mt="md"
        withAsterisk
        label="name"
        description={'name'}
        placeholder="name"
        {...form.getInputProps('name')}
      />

      <Divider my="sm" variant="dashed" />
      <Checkbox
        mt="md"
        label="is_contact"
        description={'is_contact'}
        placeholder="is_contact"
        {...form.getInputProps('is_contact')}
      />
      {form.values.is_contact ? (
        <>
          <TextInput
            mt="md"
            label="contact_address"
            description={'contact_address'}
            placeholder="contact_address"
            {...form.getInputProps('contact_address')}
          />
          <TextInput
            mt="md"
            label="contact_email"
            description={'contact_email'}
            placeholder="contact_email"
            {...form.getInputProps('contact_email')}
          />
          <TextInput
            mt="md"
            label="contact_phone_number"
            description={'contact_phone_number'}
            placeholder="contact_phone_number"
            {...form.getInputProps('contact_phone_number')}
          />
        </>
      ) : (
        <>
          <Select
            mt="md"
            label="Contact"
            required={false}
            searchable
            clearable
            allowDeselect
            disabled={contacts.isLoading}
            data={
              contacts.data?.map((contact) => ({
                value: contact.id,
                label: contact.name,
              })) ?? ['Loading...']
            }
            {...form.getInputProps('contact_id')}
          />
        </>
      )}

      <Divider my="sm" variant="dashed" />
      <Checkbox
        mt="md"
        label="is_customer"
        description={'is_customer'}
        placeholder="is_customer"
        {...form.getInputProps('is_customer')}
      />
      {form.values.is_customer && (
        <>
          <NumberInput
            mt="md"
            label="customer_credit_limit"
            description={'customer_credit_limit'}
            placeholder="customer_credit_limit"
            {...form.getInputProps('customer_credit_limit')}
          />
          <TextInput
            mt="md"
            label="customer_segment"
            description={'customer_segment'}
            placeholder="customer_segment"
            {...form.getInputProps('customer_segment')}
          />
        </>
      )}

      <Divider my="sm" variant="dashed" />
      <Checkbox
        mt="md"
        label="is_supplier"
        description={'is_supplier'}
        placeholder="is_supplier"
        {...form.getInputProps('is_supplier')}
      />
      {form.values.is_supplier && (
        <>
          <NumberInput
            mt="md"
            label="supplier_lead_time_days"
            description={'supplier_lead_time_days'}
            placeholder="supplier_lead_time_days"
            {...form.getInputProps('supplier_lead_time_days')}
          />
          <TextInput
            mt="md"
            label="supplier_payment_terms"
            description={'supplier_payment_terms'}
            placeholder="supplier_payment_terms"
            {...form.getInputProps('supplier_payment_terms')}
          />
        </>
      )}

      <Divider my="sm" variant="dashed" />
      <Checkbox
        mt="md"
        label="is_warehouse"
        description={'is_warehouse'}
        placeholder="is_warehouse"
        {...form.getInputProps('is_warehouse')}
      />
      {form.values.is_warehouse && (
        <>
          <NumberInput
            mt="md"
            label="warehouse_capacity"
            description={'warehouse_capacity'}
            placeholder="warehouse_capacity"
            {...form.getInputProps('warehouse_capacity')}
          />
        </>
      )}

      <Group position="right" mt="md">
        <Button type="button">Cancel</Button>
        <Button type="submit">Submit</Button>
      </Group>
    </form>
  );
}
