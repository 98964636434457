import { Route, Routes } from 'react-router-dom';
import { ItemCategories } from './ItemCategories';
import { Units } from './Units';

export function Configuration() {
  return (
    <Routes>
      <Route path="item-categories/*" element={<ItemCategories />} />
      <Route path="units/*" element={<Units />} />
    </Routes>
  );
}
