/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { BusinessEntitiesFilterIsContact } from './businessEntitiesFilterIsContact';
import type { BusinessEntitiesFilterIsCustomer } from './businessEntitiesFilterIsCustomer';
import type { BusinessEntitiesFilterIsSupplier } from './businessEntitiesFilterIsSupplier';
import type { BusinessEntitiesFilterIsWarehouse } from './businessEntitiesFilterIsWarehouse';
import type { BusinessEntitiesFilterName } from './businessEntitiesFilterName';

/**
 * The filter to use when searching for business_entities
 */
export interface BusinessEntitiesFilter {
  /** Is this business entity a contact? */
  is_contact?: BusinessEntitiesFilterIsContact;
  /** Is this business entity a customer? */
  is_customer?: BusinessEntitiesFilterIsCustomer;
  /** Is this business entity a supplier? */
  is_supplier?: BusinessEntitiesFilterIsSupplier;
  /** Is this business entity a warehouse? */
  is_warehouse?: BusinessEntitiesFilterIsWarehouse;
  /** The name of the business entity to search for */
  name?: BusinessEntitiesFilterName;
}
