import { Route, Routes } from 'react-router-dom';
import { InventoryItems } from './InventoryItems';

export function Inventory() {
  return (
    <Routes>
      <Route path="items/*" element={<InventoryItems />} />
    </Routes>
  );
}
