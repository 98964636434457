import { Button, Group, LoadingOverlay, Modal } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { isNull, omit, omitBy, pipe } from 'lodash/fp';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdateItemRequest, useDeleteItemsId, useGetItemsId, usePutItemsId } from '../../../api';
import { sanitizePayload } from '../../../utils';
import { Form } from './Form';
import { schema } from './schema';

export function Edit() {
  const [showDelete, { open, close }] = useDisclosure(false);
  const navigate = useNavigate();
  const { itemId } = useParams();

  const updateItemMutation = usePutItemsId({
    mutation: {
      onSuccess: () => navigate('..'),
      onError: console.error,
    },
  });

  const deleteItemMutation = useDeleteItemsId({
    mutation: {
      onSuccess: () => navigate('..'),
      onError: console.error,
    },
  });

  const handleSubmit = useCallback(
    (values: UpdateItemRequest) => {
      if (!itemId) return;

      console.log('values', values);
      updateItemMutation.mutate({ id: itemId, data: values });
    },
    [updateItemMutation.mutate],
  );

  const defaultValues = useMemo<UpdateItemRequest>(
    () => ({
      name: '',
      item_category_id: '',
      description: '',
      can_be_purchased: false,
      can_be_sold: false,
      has_inventory: false,
      has_lot_number: false,
      has_serial_number: false,
      is_input: false,
      is_perishable: false,
      is_product: false,
      is_raw_material: false,
      is_service: false,
    }),
    [],
  );

  const form = useForm<UpdateItemRequest>({
    initialValues: defaultValues,
    validate: zodResolver(schema),
    validateInputOnBlur: true,
    transformValues: sanitizePayload,
  });

  const item = useGetItemsId(String(itemId), {
    query: {
      enabled: Boolean(itemId),
      onSuccess: (item) => {
        const values = pipe(omit(['id', 'created_at', 'updated_at']), omitBy(isNull))(item);

        form.setValues(values);
        form.resetDirty();
      },
    },
  });

  if (item.isLoading || !item.data) return <LoadingOverlay visible={true} />;

  return (
    <>
      <Modal opened={showDelete} onClose={close} title="Confirm Delete">
        <p>Are you sure you want to delete {item.data.name}?</p>
        <Button
          variant={'outline'}
          color={'red'}
          onClick={() => {
            if (!itemId) return;
            deleteItemMutation.mutate({ id: itemId });
          }}
        >
          Confirm
        </Button>
      </Modal>

      <h1>Edit {item.data.name}</h1>
      <Form
        onSubmit={handleSubmit}
        onError={console.error}
        loading={updateItemMutation.isLoading}
        form={form}
      />
      <Group position="right" mt="md">
        <Button variant={'outline'} color={'red'} onClick={open}>
          Delete
        </Button>
      </Group>
    </>
  );
}
