/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  Item,
  GetItemsDefault,
  GetItemsParams,
  PostItemsDefault,
  CreateItemRequest,
  GetItemsIdDefault,
  PutItemsIdDefault,
  UpdateItemRequest,
  DeleteItemsIdDefault,
} from '.././model';
import { useCustomInstance } from '.././mutator/custom-instance';
import type { ErrorType } from '.././mutator/custom-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * List all items
 */
export const useGetItemsHook = () => {
  const getItems = useCustomInstance<Item[]>();

  return (params?: GetItemsParams, signal?: AbortSignal) => {
    return getItems({ url: `/items/`, method: 'get', params, signal });
  };
};

export const getGetItemsQueryKey = (params?: GetItemsParams) =>
  [`/items/`, ...(params ? [params] : [])] as const;

export const useGetItemsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetItemsHook>>>,
  TError = ErrorType<GetItemsDefault>,
>(
  params?: GetItemsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetItemsHook>>>, TError, TData>;
  },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetItemsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetItemsQueryKey(params);

  const getItems = useGetItemsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetItemsHook>>>> = ({
    signal,
  }) => getItems(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetItemsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetItemsHook>>>
>;
export type GetItemsQueryError = ErrorType<GetItemsDefault>;

export const useGetItems = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetItemsHook>>>,
  TError = ErrorType<GetItemsDefault>,
>(
  params?: GetItemsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetItemsHook>>>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetItemsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create a new item
 */
export const usePostItemsHook = () => {
  const postItems = useCustomInstance<Item>();

  return (createItemRequest: CreateItemRequest) => {
    return postItems({
      url: `/items/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createItemRequest,
    });
  };
};

export const usePostItemsMutationOptions = <
  TError = ErrorType<PostItemsDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostItemsHook>>>,
    TError,
    { data: CreateItemRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostItemsHook>>>,
  TError,
  { data: CreateItemRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const postItems = usePostItemsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostItemsHook>>>,
    { data: CreateItemRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postItems(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostItemsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostItemsHook>>>
>;
export type PostItemsMutationBody = CreateItemRequest;
export type PostItemsMutationError = ErrorType<PostItemsDefault>;

export const usePostItems = <TError = ErrorType<PostItemsDefault>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostItemsHook>>>,
    TError,
    { data: CreateItemRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePostItemsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get a single item
 */
export const useGetItemsIdHook = () => {
  const getItemsId = useCustomInstance<Item>();

  return (id: string, signal?: AbortSignal) => {
    return getItemsId({ url: `/items/${id}`, method: 'get', signal });
  };
};

export const getGetItemsIdQueryKey = (id: string) => [`/items/${id}`] as const;

export const useGetItemsIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetItemsIdHook>>>,
  TError = ErrorType<GetItemsIdDefault>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetItemsIdHook>>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetItemsIdHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetItemsIdQueryKey(id);

  const getItemsId = useGetItemsIdHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetItemsIdHook>>>> = ({
    signal,
  }) => getItemsId(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetItemsIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetItemsIdHook>>>
>;
export type GetItemsIdQueryError = ErrorType<GetItemsIdDefault>;

export const useGetItemsId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetItemsIdHook>>>,
  TError = ErrorType<GetItemsIdDefault>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetItemsIdHook>>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetItemsIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update an item
 */
export const usePutItemsIdHook = () => {
  const putItemsId = useCustomInstance<Item>();

  return (id: string, updateItemRequest: UpdateItemRequest) => {
    return putItemsId({
      url: `/items/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateItemRequest,
    });
  };
};

export const usePutItemsIdMutationOptions = <
  TError = ErrorType<PutItemsIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutItemsIdHook>>>,
    TError,
    { id: string; data: UpdateItemRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePutItemsIdHook>>>,
  TError,
  { id: string; data: UpdateItemRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const putItemsId = usePutItemsIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePutItemsIdHook>>>,
    { id: string; data: UpdateItemRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return putItemsId(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutItemsIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePutItemsIdHook>>>
>;
export type PutItemsIdMutationBody = UpdateItemRequest;
export type PutItemsIdMutationError = ErrorType<PutItemsIdDefault>;

export const usePutItemsId = <TError = ErrorType<PutItemsIdDefault>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutItemsIdHook>>>,
    TError,
    { id: string; data: UpdateItemRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePutItemsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Delete an item
 */
export const useDeleteItemsIdHook = () => {
  const deleteItemsId = useCustomInstance<Item>();

  return (id: string) => {
    return deleteItemsId({ url: `/items/${id}`, method: 'delete' });
  };
};

export const useDeleteItemsIdMutationOptions = <
  TError = ErrorType<DeleteItemsIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteItemsIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteItemsIdHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteItemsId = useDeleteItemsIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteItemsIdHook>>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteItemsId(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteItemsIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteItemsIdHook>>>
>;

export type DeleteItemsIdMutationError = ErrorType<DeleteItemsIdDefault>;

export const useDeleteItemsId = <
  TError = ErrorType<DeleteItemsIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteItemsIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteItemsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
