/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { InventoryLevelsPaginationOrderBy } from './inventoryLevelsPaginationOrderBy';
import type { InventoryLevelsPaginationOrderDirection } from './inventoryLevelsPaginationOrderDirection';
import type { InventoryLevelsPaginationPage } from './inventoryLevelsPaginationPage';
import type { InventoryLevelsPaginationPerPage } from './inventoryLevelsPaginationPerPage';

export interface InventoryLevelsPagination {
  /** The field to order by. Defaults to `id` if not specified. */
  order_by?: InventoryLevelsPaginationOrderBy;
  /** The direction to order by. Defaults to `asc` if not specified. */
  order_direction?: InventoryLevelsPaginationOrderDirection;
  /** The page number to return. Defaults to 1 if not specified. */
  page?: InventoryLevelsPaginationPage;
  /** The number of items per page. Defaults to 10 if not specified. */
  per_page?: InventoryLevelsPaginationPerPage;
}
