import { useForm, zodResolver } from '@mantine/form';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateUnitRequest, usePostUnits } from '../../../api';
import { sanitizePayload } from '../../../utils';
import { Form } from './Form';
import { schema } from './schema';

export function New() {
  const navigate = useNavigate();

  const createUnitMutation = usePostUnits({
    mutation: {
      onSuccess: () => navigate('..'),
      onError: console.error,
    },
  });

  const handleSubmit = useCallback(
    (values: CreateUnitRequest) => {
      return createUnitMutation.mutate({ data: values });
    },
    [createUnitMutation.mutate],
  );

  const defaultValues = useMemo<CreateUnitRequest>(
    () => ({
      name: '',
      code: '',
      description: '',
      base: 10,
      exponent: 2,
    }),
    [],
  );

  const form = useForm<CreateUnitRequest>({
    initialValues: defaultValues,
    validate: zodResolver(schema),
    validateInputOnBlur: true,
    transformValues: sanitizePayload,
  });

  return (
    <>
      <h1>New Unit</h1>
      <Form
        onSubmit={handleSubmit}
        onError={console.error}
        loading={createUnitMutation.isLoading}
        form={form}
      />
    </>
  );
}
