export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function sanitizePayload<T extends { [key: string]: any }>(values: T): T {
  return Object.entries(values).reduce((acc, [key, value]) => {
    if (value === '') return { ...acc, [key]: null };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    return { ...acc, [key]: value };
  }, {} as T);
}
