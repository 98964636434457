import { Button, Group, LoadingOverlay, Modal } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { isNull, omit, omitBy, pipe } from 'lodash/fp';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdateUnitRequest, useDeleteUnitsId, useGetUnitsId, usePutUnitsId } from '../../../api';
import { sanitizePayload } from '../../../utils';
import { Form } from './Form';
import { schema } from './schema';

export function Edit() {
  const [showDelete, { open, close }] = useDisclosure(false);
  const navigate = useNavigate();
  const { unitId } = useParams();

  const updateUnitMutation = usePutUnitsId({
    mutation: {
      onSuccess: () => navigate('..'),
      onError: console.error,
    },
  });

  const deleteUnitMutation = useDeleteUnitsId({
    mutation: {
      onSuccess: () => navigate('..'),
      onError: console.error,
    },
  });

  const handleSubmit = useCallback(
    (values: UpdateUnitRequest) => {
      if (!unitId) return;

      console.log('values', values);
      updateUnitMutation.mutate({ id: unitId, data: values });
    },
    [updateUnitMutation.mutate],
  );

  const defaultValues = useMemo<UpdateUnitRequest>(
    () => ({
      name: '',
      code: '',
      description: '',
      base: 10,
      exponent: 2,
    }),
    [],
  );

  const form = useForm<UpdateUnitRequest>({
    initialValues: defaultValues,
    validate: zodResolver(schema),
    validateInputOnBlur: true,
    transformValues: sanitizePayload,
  });

  const unit = useGetUnitsId(String(unitId), {
    query: {
      enabled: Boolean(unitId),
      onSuccess: (unit) => {
        const values = pipe(omit(['id', 'created_at', 'updated_at']), omitBy(isNull))(unit);

        form.setValues(values);
        form.resetDirty();
      },
    },
  });

  if (unit.isLoading || !unit.data) return <LoadingOverlay visible={true} />;

  return (
    <>
      <Modal opened={showDelete} onClose={close} title="Confirm Delete">
        <p>Are you sure you want to delete {unit.data.name}?</p>
        <Button
          variant={'outline'}
          color={'red'}
          onClick={() => {
            if (!unitId) return;
            deleteUnitMutation.mutate({ id: unitId });
          }}
        >
          Confirm
        </Button>
      </Modal>

      <h1>Edit {unit.data.name}</h1>
      <Form
        onSubmit={handleSubmit}
        onError={console.error}
        loading={updateUnitMutation.isLoading}
        form={form}
      />
      <Group position="right" mt="md">
        <Button variant={'outline'} color={'red'} onClick={open}>
          Delete
        </Button>
      </Group>
    </>
  );
}
