/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { UpdateFinanceDocumentEntryRequestUnitAmount } from './updateFinanceDocumentEntryRequestUnitAmount';
import type { UpdateFinanceDocumentEntryRequestUnitPrice } from './updateFinanceDocumentEntryRequestUnitPrice';

export interface UpdateFinanceDocumentEntryRequest {
  /** Identifier of the finance document this entry belongs to */
  finance_document_id: string;
  /** Identifier of the item this entry represents */
  item_id: string;
  /** The amount of items described in this entry */
  unit_amount: UpdateFinanceDocumentEntryRequestUnitAmount;
  /** The unitary price for 1 item */
  unit_price: UpdateFinanceDocumentEntryRequestUnitPrice;
}
