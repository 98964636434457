import { Button, Group, LoadingOverlay, Modal } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { isNull, omit, omitBy, pipe } from 'lodash/fp';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  UpdateBusinessEntityRequest,
  useDeleteBusinessEntitiesId,
  useGetBusinessEntitiesId,
  usePutBusinessEntitiesId,
} from '../../../api';
import { sanitizePayload } from '../../../utils';
import { Form } from './Form';
import { schema } from './schema';

export function Edit() {
  const [showDelete, { open, close }] = useDisclosure(false);
  const navigate = useNavigate();
  const { businessEntityId } = useParams();

  const updateBusinessEntityMutation = usePutBusinessEntitiesId({
    mutation: {
      onSuccess: () => navigate('..'),
      onError: console.error,
    },
  });

  const deleteBusinessEntityMutation = useDeleteBusinessEntitiesId({
    mutation: {
      onSuccess: () => navigate('..'),
      onError: console.error,
    },
  });

  const handleSubmit = useCallback(
    (values: UpdateBusinessEntityRequest) => {
      if (!businessEntityId) return;

      console.log('values', values);
      updateBusinessEntityMutation.mutate({
        id: businessEntityId,
        data: values,
      });
    },
    [updateBusinessEntityMutation.mutate],
  );

  const defaultValues = useMemo<UpdateBusinessEntityRequest>(
    () => ({
      contact_address: '',
      contact_email: '',
      contact_id: '',
      contact_phone_number: '',
      customer_credit_limit: 0,
      customer_segment: '',
      is_contact: false,
      is_customer: false,
      is_supplier: false,
      is_warehouse: false,
      name: '',
      supplier_lead_time_days: 0,
      supplier_payment_terms: '',
      warehouse_capacity: 0,
    }),
    [],
  );

  const form = useForm<UpdateBusinessEntityRequest>({
    initialValues: defaultValues,
    validate: zodResolver(schema),
    validateInputOnBlur: true,
    transformValues: sanitizePayload,
  });

  const item = useGetBusinessEntitiesId(String(businessEntityId), {
    query: {
      enabled: Boolean(businessEntityId),
      onSuccess: (businessEntity) => {
        const values = pipe(
          omit(['id', 'created_at', 'updated_at']),
          omitBy(isNull),
        )(businessEntity);

        form.setValues(values);
        form.resetDirty();
      },
    },
  });

  if (item.isLoading || !item.data) return <LoadingOverlay visible={true} />;

  return (
    <>
      <Modal opened={showDelete} onClose={close} title="Confirm Delete">
        <p>Are you sure you want to delete {item.data.name}?</p>
        <Button
          variant={'outline'}
          color={'red'}
          onClick={() => {
            if (!businessEntityId) return;
            deleteBusinessEntityMutation.mutate({ id: businessEntityId });
          }}
        >
          Confirm
        </Button>
      </Modal>

      <h1>Edit {item.data.name}</h1>
      <Form
        onSubmit={handleSubmit}
        onError={console.error}
        loading={updateBusinessEntityMutation.isLoading}
        form={form}
      />
      <Group position="right" mt="md">
        <Button variant={'outline'} color={'red'} onClick={open}>
          Delete
        </Button>
      </Group>
    </>
  );
}
