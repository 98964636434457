/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { InventoryItemsPaginationOrderBy } from './inventoryItemsPaginationOrderBy';
import type { InventoryItemsPaginationOrderDirection } from './inventoryItemsPaginationOrderDirection';
import type { InventoryItemsPaginationPage } from './inventoryItemsPaginationPage';
import type { InventoryItemsPaginationPerPage } from './inventoryItemsPaginationPerPage';

export interface InventoryItemsPagination {
  /** The field to order by. Defaults to `id` if not specified. */
  order_by?: InventoryItemsPaginationOrderBy;
  /** The direction to order by. Defaults to `asc` if not specified. */
  order_direction?: InventoryItemsPaginationOrderDirection;
  /** The page number to return. Defaults to 1 if not specified. */
  page?: InventoryItemsPaginationPage;
  /** The number of items per page. Defaults to 10 if not specified. */
  per_page?: InventoryItemsPaginationPerPage;
}
