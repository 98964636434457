/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { FinanceDocumentEntriesPaginationOrderBy } from './financeDocumentEntriesPaginationOrderBy';
import type { FinanceDocumentEntriesPaginationOrderDirection } from './financeDocumentEntriesPaginationOrderDirection';
import type { FinanceDocumentEntriesPaginationPage } from './financeDocumentEntriesPaginationPage';
import type { FinanceDocumentEntriesPaginationPerPage } from './financeDocumentEntriesPaginationPerPage';

export interface FinanceDocumentEntriesPagination {
  /** The field to order by. Defaults to `id` if not specified. */
  order_by?: FinanceDocumentEntriesPaginationOrderBy;
  /** The direction to order by. Defaults to `asc` if not specified. */
  order_direction?: FinanceDocumentEntriesPaginationOrderDirection;
  /** The page number to return. Defaults to 1 if not specified. */
  page?: FinanceDocumentEntriesPaginationPage;
  /** The number of items per page. Defaults to 10 if not specified. */
  per_page?: FinanceDocumentEntriesPaginationPerPage;
}
