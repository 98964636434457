/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */

export type UnitsOrderBy = (typeof UnitsOrderBy)[keyof typeof UnitsOrderBy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnitsOrderBy = {
  id: 'id',
  created_at: 'created_at',
  code: 'code',
  name: 'name',
} as const;
