/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { InventoryLevelAssigned } from './inventoryLevelAssigned';
import type { InventoryLevelAvailable } from './inventoryLevelAvailable';
import type { InventoryLevelCriticalFloor } from './inventoryLevelCriticalFloor';
import type { InventoryLevelDailyUsageAverage } from './inventoryLevelDailyUsageAverage';
import type { InventoryLevelDailyUsageStandardDeviation } from './inventoryLevelDailyUsageStandardDeviation';
import type { InventoryLevelDescription } from './inventoryLevelDescription';
import type { InventoryLevelEconomicLotSize } from './inventoryLevelEconomicLotSize';
import type { InventoryLevelExcessFloor } from './inventoryLevelExcessFloor';
import type { InventoryLevelInTransit } from './inventoryLevelInTransit';
import type { InventoryLevelLeadTime } from './inventoryLevelLeadTime';
import type { InventoryLevelLeadTimeAverage } from './inventoryLevelLeadTimeAverage';
import type { InventoryLevelLeadTimeStandardDeviation } from './inventoryLevelLeadTimeStandardDeviation';
import type { InventoryLevelLocation } from './inventoryLevelLocation';
import type { InventoryLevelMaximumInventory } from './inventoryLevelMaximumInventory';
import type { InventoryLevelMinimumInventory } from './inventoryLevelMinimumInventory';
import type { InventoryLevelOrdered } from './inventoryLevelOrdered';
import type { InventoryLevelReorderFloor } from './inventoryLevelReorderFloor';
import type { InventoryLevelRequested } from './inventoryLevelRequested';
import type { InventoryLevelReserved } from './inventoryLevelReserved';
import type { InventoryLevelSafetyFactor } from './inventoryLevelSafetyFactor';
import type { InventoryLevelTotal } from './inventoryLevelTotal';

/**
 * An inventory level describes how many inventory items are present in a particular warehouse. These levels are affected by inventory movements
 */
export interface InventoryLevel {
  /** How many units of the inventory item are assigned */
  assigned: InventoryLevelAssigned;
  /** How many units of the inventory item are available */
  available: InventoryLevelAvailable;
  /** Timestamp of when the inventory level was created */
  created_at: string;
  /** The lower limit of the inventory item at which a critical reorder must be made */
  critical_floor?: InventoryLevelCriticalFloor;
  /** The average daily usage of the inventory item */
  daily_usage_average?: InventoryLevelDailyUsageAverage;
  /** The standard deviation of the daily usage of the inventory item */
  daily_usage_standard_deviation?: InventoryLevelDailyUsageStandardDeviation;
  /** Description of the inventory item */
  description?: InventoryLevelDescription;
  /** The average economic lot size of the inventory item */
  economic_lot_size?: InventoryLevelEconomicLotSize;
  /** The lower limit of the inventory item after which inventory is excessive */
  excess_floor?: InventoryLevelExcessFloor;
  /** Unique identifier of the inventory level */
  id: string;
  /** How many units of the inventory item are in transit */
  in_transit: InventoryLevelInTransit;
  /** Unique identifier of the inventory item */
  inventory_item_id: string;
  /** Do we need this value? */
  lead_time?: InventoryLevelLeadTime;
  /** The average lead time of the inventory item */
  lead_time_average?: InventoryLevelLeadTimeAverage;
  /** The standard deviation of the lead time of the inventory item */
  lead_time_standard_deviation?: InventoryLevelLeadTimeStandardDeviation;
  /** The location of the inventory item within the warehouse */
  location?: InventoryLevelLocation;
  /** The maximum inventory of the inventory item we should have on stock at all times */
  maximum_inventory?: InventoryLevelMaximumInventory;
  /** The minimum inventory of the inventory item we should have on stock at all times */
  minimum_inventory?: InventoryLevelMinimumInventory;
  /** Name of the inventory item */
  name: string;
  /** How many units of the inventory item are ordered */
  ordered: InventoryLevelOrdered;
  /** The lower limit of the inventory item at which a reorder must be made */
  reorder_floor?: InventoryLevelReorderFloor;
  /** How many units of the inventory item are requested */
  requested: InventoryLevelRequested;
  /** How many units of the inventory item are reserved */
  reserved: InventoryLevelReserved;
  /** The safety factor of the inventory item */
  safety_factor?: InventoryLevelSafetyFactor;
  /** How many units of the inventory item are on hand. This is the sum of reserved, assigned, requested, ordered, in_transit, available */
  total: InventoryLevelTotal;
  /** Timestamp of when the inventory level was last updated */
  updated_at: string;
  /** Unique identifier of the warehouse */
  warehouse_id: string;
}
