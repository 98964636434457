/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { BusinessEntityContactAddress } from './businessEntityContactAddress';
import type { BusinessEntityContactEmail } from './businessEntityContactEmail';
import type { BusinessEntityContactId } from './businessEntityContactId';
import type { BusinessEntityContactPhoneNumber } from './businessEntityContactPhoneNumber';
import type { BusinessEntityCustomerCreditLimit } from './businessEntityCustomerCreditLimit';
import type { BusinessEntityCustomerSegment } from './businessEntityCustomerSegment';
import type { BusinessEntitySupplierLeadTimeDays } from './businessEntitySupplierLeadTimeDays';
import type { BusinessEntitySupplierPaymentTerms } from './businessEntitySupplierPaymentTerms';
import type { BusinessEntityWarehouseCapacity } from './businessEntityWarehouseCapacity';

/**
 * A business entity is a person, company or any entity that a company interacts with. It could be itself, an employee, a customer, a supplier, among others
 */
export interface BusinessEntity {
  /** The address of the business entity when it is a contact */
  contact_address?: BusinessEntityContactAddress;
  /** The email of the business entity when it is a contact */
  contact_email?: BusinessEntityContactEmail;
  /** The contact id of the business entity */
  contact_id?: BusinessEntityContactId;
  /** The phone number of the business entity when it is a contact */
  contact_phone_number?: BusinessEntityContactPhoneNumber;
  /** Timestamp of when the inventory item was created */
  created_at: string;
  /** The credit limit of the business entity when it is a customer */
  customer_credit_limit?: BusinessEntityCustomerCreditLimit;
  /** The segment of the business entity when it is a customer */
  customer_segment?: BusinessEntityCustomerSegment;
  /** Unique identifier of the business entity */
  id: string;
  /** Is this business entity a contact? */
  is_contact: boolean;
  /** Is this business entity a customer? */
  is_customer: boolean;
  /** Is this business entity a supplier? */
  is_supplier: boolean;
  /** Is this business entity a warehouse? */
  is_warehouse: boolean;
  /** Name of the business entity */
  name: string;
  /** The lead time in days of the business entity when it is a supplier */
  supplier_lead_time_days?: BusinessEntitySupplierLeadTimeDays;
  /** The payment terms of the business entity when it is a supplier */
  supplier_payment_terms?: BusinessEntitySupplierPaymentTerms;
  /** Timestamp of when the inventory item was last updated */
  updated_at: string;
  /** The capacity of the business entity when it is a warehouse */
  warehouse_capacity?: BusinessEntityWarehouseCapacity;
}
