/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { FinanceDocumentsPaginationOrderBy } from './financeDocumentsPaginationOrderBy';
import type { FinanceDocumentsPaginationOrderDirection } from './financeDocumentsPaginationOrderDirection';
import type { FinanceDocumentsPaginationPage } from './financeDocumentsPaginationPage';
import type { FinanceDocumentsPaginationPerPage } from './financeDocumentsPaginationPerPage';

export interface FinanceDocumentsPagination {
  /** The field to order by. Defaults to `id` if not specified. */
  order_by?: FinanceDocumentsPaginationOrderBy;
  /** The direction to order by. Defaults to `asc` if not specified. */
  order_direction?: FinanceDocumentsPaginationOrderDirection;
  /** The page number to return. Defaults to 1 if not specified. */
  page?: FinanceDocumentsPaginationPage;
  /** The number of items per page. Defaults to 10 if not specified. */
  per_page?: FinanceDocumentsPaginationPerPage;
}
