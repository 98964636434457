import { useForm, zodResolver } from '@mantine/form';
import { isEmpty, omitBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateInventoryItemRequest, usePostInventoryItems } from '../../../api';
import { Form } from './Form';
import { schema } from './schema';

export function New() {
  const navigate = useNavigate();

  const inventoryItemMutation = usePostInventoryItems({
    mutation: {
      onSuccess: () => navigate('..'),
      onError: console.error,
    },
  });

  const handleSubmit = useCallback(
    (values: CreateInventoryItemRequest) => inventoryItemMutation.mutate({ data: values }),
    [inventoryItemMutation.mutate],
  );

  const defaultValues = useMemo<CreateInventoryItemRequest>(
    () => ({
      barcode: '',
      default_delivery_warehouse_id: '',
      default_reception_warehouse_id: '',
      item_id: '',
      sku: '',
      supplier_id: '',
      unit_id: '',
    }),
    [],
  );

  const form = useForm<CreateInventoryItemRequest>({
    initialValues: defaultValues,
    validate: zodResolver(schema),
    validateInputOnBlur: true,
    transformValues: (values) => omitBy(values, isEmpty) as CreateInventoryItemRequest,
  });

  return (
    <>
      <h1>New Inventory Item</h1>
      <Form
        onSubmit={handleSubmit}
        onError={console.error}
        loading={inventoryItemMutation.isLoading}
        form={form}
      />
    </>
  );
}
