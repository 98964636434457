import { Button, Group, LoadingOverlay, NumberInput, Textarea, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { FormErrors } from '@mantine/form/lib/types';

interface Props<T> {
  onSubmit: (values: T) => void;
  onError: (errors: FormErrors, values: T) => void;
  loading: boolean;
  form: UseFormReturnType<T>;
}

export function Form<T>({ form, onSubmit, onError, loading }: Props<T>) {
  return (
    <form onSubmit={form.onSubmit(onSubmit, onError)}>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <TextInput
        withAsterisk
        required
        mt="md"
        label="name"
        description={'name of this unit.'}
        placeholder="name"
        {...form.getInputProps('name')}
      />
      <TextInput
        withAsterisk
        mt="md"
        label="code"
        description={'code of this unit.'}
        placeholder="code"
        {...form.getInputProps('code')}
      />
      <Textarea
        mt="md"
        label="Description"
        description={'Describe this item.'}
        placeholder="Some example of a unit"
        autosize
        minRows={2}
        {...form.getInputProps('description')}
      />
      <NumberInput
        withAsterisk
        mt="md"
        label="base"
        step={1}
        description={'base of this unit.'}
        placeholder="base"
        {...form.getInputProps('base')}
      />
      <NumberInput
        withAsterisk
        mt="md"
        step={1}
        label="exponent"
        description={'exponent of this unit.'}
        placeholder="exponent"
        {...form.getInputProps('exponent')}
      />
      <Group position="right" mt="md">
        <Button type="button">Cancel</Button>
        <Button type="submit">Submit</Button>
      </Group>
    </form>
  );
}
