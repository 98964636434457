/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { InventoryLevelsFilterInventoryItemId } from './inventoryLevelsFilterInventoryItemId';
import type { InventoryLevelsFilterName } from './inventoryLevelsFilterName';
import type { InventoryLevelsFilterWarehouseId } from './inventoryLevelsFilterWarehouseId';

/**
 * The filter to use when searching for inventory_levels
 */
export interface InventoryLevelsFilter {
  /** Unique identifier of the inventory item */
  inventory_item_id?: InventoryLevelsFilterInventoryItemId;
  /** Name of the inventory item */
  name?: InventoryLevelsFilterName;
  /** Unique identifier of the warehouse */
  warehouse_id?: InventoryLevelsFilterWarehouseId;
}
