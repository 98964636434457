/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */

/**
 * The number of items per page. Defaults to 10 if not specified.
 */
export type InventoryMovementsPaginationPerPage = number | null;
