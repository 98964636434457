/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { FinanceDocumentsFilterDate } from './financeDocumentsFilterDate';
import type { FinanceDocumentsFilterDocumentType } from './financeDocumentsFilterDocumentType';
import type { FinanceDocumentsFilterStatus } from './financeDocumentsFilterStatus';

/**
 * The filter to use when searching for finance documents
 */
export interface FinanceDocumentsFilter {
  /** The date of the finance document to search for */
  date?: FinanceDocumentsFilterDate;
  /** The document type of the finance document to search for */
  document_type?: FinanceDocumentsFilterDocumentType;
  /** The status of the finance document to search for */
  status?: FinanceDocumentsFilterStatus;
}
