/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { FinanceDocumentEntriesFilterFinanceDocumentId } from './financeDocumentEntriesFilterFinanceDocumentId';
import type { FinanceDocumentEntriesFilterItemId } from './financeDocumentEntriesFilterItemId';

/**
 * The filter to use when searching for finance_document_entries
 */
export interface FinanceDocumentEntriesFilter {
  /** Identifier of the finance document this entry belongs to */
  finance_document_id?: FinanceDocumentEntriesFilterFinanceDocumentId;
  /** Identifier of the item this entry represents */
  item_id?: FinanceDocumentEntriesFilterItemId;
}
