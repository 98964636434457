/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { ItemsPaginationOrderBy } from './itemsPaginationOrderBy';
import type { ItemsPaginationOrderDirection } from './itemsPaginationOrderDirection';
import type { ItemsPaginationPage } from './itemsPaginationPage';
import type { ItemsPaginationPerPage } from './itemsPaginationPerPage';

export interface ItemsPagination {
  order_by?: ItemsPaginationOrderBy;
  order_direction?: ItemsPaginationOrderDirection;
  page?: ItemsPaginationPage;
  per_page?: ItemsPaginationPerPage;
}
