/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  InventoryLevel,
  GetInventoryLevelsDefault,
  GetInventoryLevelsParams,
  PostInventoryLevelsDefault,
  CreateInventoryLevelRequest,
  GetInventoryLevelsIdDefault,
  PutInventoryLevelsIdDefault,
  UpdateInventoryLevelRequest,
  DeleteInventoryLevelsIdDefault,
} from '.././model';
import { useCustomInstance } from '.././mutator/custom-instance';
import type { ErrorType } from '.././mutator/custom-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * List all inventory levels
 */
export const useGetInventoryLevelsHook = () => {
  const getInventoryLevels = useCustomInstance<InventoryLevel[]>();

  return (params?: GetInventoryLevelsParams, signal?: AbortSignal) => {
    return getInventoryLevels({ url: `/inventory-levels/`, method: 'get', params, signal });
  };
};

export const getGetInventoryLevelsQueryKey = (params?: GetInventoryLevelsParams) =>
  [`/inventory-levels/`, ...(params ? [params] : [])] as const;

export const useGetInventoryLevelsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInventoryLevelsHook>>>,
  TError = ErrorType<GetInventoryLevelsDefault>,
>(
  params?: GetInventoryLevelsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetInventoryLevelsHook>>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetInventoryLevelsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInventoryLevelsQueryKey(params);

  const getInventoryLevels = useGetInventoryLevelsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetInventoryLevelsHook>>>
  > = ({ signal }) => getInventoryLevels(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetInventoryLevelsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetInventoryLevelsHook>>>
>;
export type GetInventoryLevelsQueryError = ErrorType<GetInventoryLevelsDefault>;

export const useGetInventoryLevels = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInventoryLevelsHook>>>,
  TError = ErrorType<GetInventoryLevelsDefault>,
>(
  params?: GetInventoryLevelsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetInventoryLevelsHook>>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetInventoryLevelsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create a new inventory level
 */
export const usePostInventoryLevelsHook = () => {
  const postInventoryLevels = useCustomInstance<InventoryLevel>();

  return (createInventoryLevelRequest: CreateInventoryLevelRequest) => {
    return postInventoryLevels({
      url: `/inventory-levels/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createInventoryLevelRequest,
    });
  };
};

export const usePostInventoryLevelsMutationOptions = <
  TError = ErrorType<PostInventoryLevelsDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostInventoryLevelsHook>>>,
    TError,
    { data: CreateInventoryLevelRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostInventoryLevelsHook>>>,
  TError,
  { data: CreateInventoryLevelRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const postInventoryLevels = usePostInventoryLevelsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostInventoryLevelsHook>>>,
    { data: CreateInventoryLevelRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postInventoryLevels(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostInventoryLevelsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostInventoryLevelsHook>>>
>;
export type PostInventoryLevelsMutationBody = CreateInventoryLevelRequest;
export type PostInventoryLevelsMutationError = ErrorType<PostInventoryLevelsDefault>;

export const usePostInventoryLevels = <
  TError = ErrorType<PostInventoryLevelsDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostInventoryLevelsHook>>>,
    TError,
    { data: CreateInventoryLevelRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePostInventoryLevelsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get a single inventory level
 */
export const useGetInventoryLevelsIdHook = () => {
  const getInventoryLevelsId = useCustomInstance<InventoryLevel>();

  return (id: string, signal?: AbortSignal) => {
    return getInventoryLevelsId({ url: `/inventory-levels/${id}`, method: 'get', signal });
  };
};

export const getGetInventoryLevelsIdQueryKey = (id: string) => [`/inventory-levels/${id}`] as const;

export const useGetInventoryLevelsIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInventoryLevelsIdHook>>>,
  TError = ErrorType<GetInventoryLevelsIdDefault>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetInventoryLevelsIdHook>>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetInventoryLevelsIdHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInventoryLevelsIdQueryKey(id);

  const getInventoryLevelsId = useGetInventoryLevelsIdHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetInventoryLevelsIdHook>>>
  > = ({ signal }) => getInventoryLevelsId(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetInventoryLevelsIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetInventoryLevelsIdHook>>>
>;
export type GetInventoryLevelsIdQueryError = ErrorType<GetInventoryLevelsIdDefault>;

export const useGetInventoryLevelsId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInventoryLevelsIdHook>>>,
  TError = ErrorType<GetInventoryLevelsIdDefault>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetInventoryLevelsIdHook>>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetInventoryLevelsIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update an inventory level
 */
export const usePutInventoryLevelsIdHook = () => {
  const putInventoryLevelsId = useCustomInstance<InventoryLevel>();

  return (id: string, updateInventoryLevelRequest: UpdateInventoryLevelRequest) => {
    return putInventoryLevelsId({
      url: `/inventory-levels/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateInventoryLevelRequest,
    });
  };
};

export const usePutInventoryLevelsIdMutationOptions = <
  TError = ErrorType<PutInventoryLevelsIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutInventoryLevelsIdHook>>>,
    TError,
    { id: string; data: UpdateInventoryLevelRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePutInventoryLevelsIdHook>>>,
  TError,
  { id: string; data: UpdateInventoryLevelRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const putInventoryLevelsId = usePutInventoryLevelsIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePutInventoryLevelsIdHook>>>,
    { id: string; data: UpdateInventoryLevelRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return putInventoryLevelsId(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutInventoryLevelsIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePutInventoryLevelsIdHook>>>
>;
export type PutInventoryLevelsIdMutationBody = UpdateInventoryLevelRequest;
export type PutInventoryLevelsIdMutationError = ErrorType<PutInventoryLevelsIdDefault>;

export const usePutInventoryLevelsId = <
  TError = ErrorType<PutInventoryLevelsIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutInventoryLevelsIdHook>>>,
    TError,
    { id: string; data: UpdateInventoryLevelRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePutInventoryLevelsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Delete an inventory level
 */
export const useDeleteInventoryLevelsIdHook = () => {
  const deleteInventoryLevelsId = useCustomInstance<InventoryLevel>();

  return (id: string) => {
    return deleteInventoryLevelsId({ url: `/inventory-levels/${id}`, method: 'delete' });
  };
};

export const useDeleteInventoryLevelsIdMutationOptions = <
  TError = ErrorType<DeleteInventoryLevelsIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteInventoryLevelsIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteInventoryLevelsIdHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteInventoryLevelsId = useDeleteInventoryLevelsIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteInventoryLevelsIdHook>>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteInventoryLevelsId(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteInventoryLevelsIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteInventoryLevelsIdHook>>>
>;

export type DeleteInventoryLevelsIdMutationError = ErrorType<DeleteInventoryLevelsIdDefault>;

export const useDeleteInventoryLevelsId = <
  TError = ErrorType<DeleteInventoryLevelsIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteInventoryLevelsIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteInventoryLevelsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
