/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { InventoryMovementsFilterDestinationBusinessEntityId } from './inventoryMovementsFilterDestinationBusinessEntityId';
import type { InventoryMovementsFilterOriginBusinessEntityId } from './inventoryMovementsFilterOriginBusinessEntityId';
import type { InventoryMovementsFilterReference } from './inventoryMovementsFilterReference';

/**
 * The filter to use when searching for inventory movements
 */
export interface InventoryMovementsFilter {
  /** ID of the destination business entity */
  destination_business_entity_id?: InventoryMovementsFilterDestinationBusinessEntityId;
  /** ID of the origin business entity */
  origin_business_entity_id?: InventoryMovementsFilterOriginBusinessEntityId;
  /** A reference code */
  reference?: InventoryMovementsFilterReference;
}
