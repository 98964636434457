/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */

export type UnitsOrderDirection = (typeof UnitsOrderDirection)[keyof typeof UnitsOrderDirection];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnitsOrderDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;
