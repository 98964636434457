/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { ItemCategoriesPaginationOrderBy } from './itemCategoriesPaginationOrderBy';
import type { ItemCategoriesPaginationOrderDirection } from './itemCategoriesPaginationOrderDirection';
import type { ItemCategoriesPaginationPage } from './itemCategoriesPaginationPage';
import type { ItemCategoriesPaginationPerPage } from './itemCategoriesPaginationPerPage';

export interface ItemCategoriesPagination {
  /** The field to order by. Defaults to `id` if not specified. */
  order_by?: ItemCategoriesPaginationOrderBy;
  /** The direction to order by. Defaults to `asc` if not specified. */
  order_direction?: ItemCategoriesPaginationOrderDirection;
  /** The page number to return. Defaults to 1 if not specified. */
  page?: ItemCategoriesPaginationPage;
  /** The number of items per page. Defaults to 10 if not specified. */
  per_page?: ItemCategoriesPaginationPerPage;
}
