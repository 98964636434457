import { Anchor, Button, Group, Table, Title, Tooltip } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { useGetInventoryItems } from '../../../api';
import { Loading } from '../../../components/Loading';

export function List() {
  const inventoryItems = useGetInventoryItems();

  if (inventoryItems.isLoading) return <Loading />;
  if (inventoryItems.isError) return <h1>{inventoryItems.error?.message}</h1>;

  return (
    <div>
      <Group position={'apart'} p={'md'}>
        <Title>Inventory Item</Title>
        <Tooltip label="Add Inventory Item">
          <Link to={'new'}>
            <Button variant="outline">
              <IconPlus size={20} />
            </Button>
          </Link>
        </Tooltip>
      </Group>
      <Table striped>
        <thead>
          <tr>
            <th scope="col">SKU</th>
            <th scope="col">Item</th>
          </tr>
        </thead>
        <tbody>
          {inventoryItems.data.map((inventoryItem) => (
            <tr key={inventoryItem.id}>
              <td>
                <Anchor component={Link} to={inventoryItem.id}>
                  {inventoryItem.sku}
                </Anchor>
              </td>
              <td>{inventoryItem.item_id}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
