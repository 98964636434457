/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { CreateInventoryLevelRequestAssigned } from './createInventoryLevelRequestAssigned';
import type { CreateInventoryLevelRequestAvailable } from './createInventoryLevelRequestAvailable';
import type { CreateInventoryLevelRequestCriticalFloor } from './createInventoryLevelRequestCriticalFloor';
import type { CreateInventoryLevelRequestDailyUsageAverage } from './createInventoryLevelRequestDailyUsageAverage';
import type { CreateInventoryLevelRequestDailyUsageStandardDeviation } from './createInventoryLevelRequestDailyUsageStandardDeviation';
import type { CreateInventoryLevelRequestDescription } from './createInventoryLevelRequestDescription';
import type { CreateInventoryLevelRequestEconomicLotSize } from './createInventoryLevelRequestEconomicLotSize';
import type { CreateInventoryLevelRequestExcessFloor } from './createInventoryLevelRequestExcessFloor';
import type { CreateInventoryLevelRequestInTransit } from './createInventoryLevelRequestInTransit';
import type { CreateInventoryLevelRequestLeadTime } from './createInventoryLevelRequestLeadTime';
import type { CreateInventoryLevelRequestLeadTimeAverage } from './createInventoryLevelRequestLeadTimeAverage';
import type { CreateInventoryLevelRequestLeadTimeStandardDeviation } from './createInventoryLevelRequestLeadTimeStandardDeviation';
import type { CreateInventoryLevelRequestLocation } from './createInventoryLevelRequestLocation';
import type { CreateInventoryLevelRequestMaximumInventory } from './createInventoryLevelRequestMaximumInventory';
import type { CreateInventoryLevelRequestMinimumInventory } from './createInventoryLevelRequestMinimumInventory';
import type { CreateInventoryLevelRequestOrdered } from './createInventoryLevelRequestOrdered';
import type { CreateInventoryLevelRequestReorderFloor } from './createInventoryLevelRequestReorderFloor';
import type { CreateInventoryLevelRequestRequested } from './createInventoryLevelRequestRequested';
import type { CreateInventoryLevelRequestReserved } from './createInventoryLevelRequestReserved';
import type { CreateInventoryLevelRequestSafetyFactor } from './createInventoryLevelRequestSafetyFactor';
import type { CreateInventoryLevelRequestTotal } from './createInventoryLevelRequestTotal';

export interface CreateInventoryLevelRequest {
  /** How many units of the inventory item are assigned */
  assigned: CreateInventoryLevelRequestAssigned;
  /** How many units of the inventory item are available */
  available: CreateInventoryLevelRequestAvailable;
  /** The lower limit of the inventory item at which a critical reorder must be made */
  critical_floor?: CreateInventoryLevelRequestCriticalFloor;
  /** The average daily usage of the inventory item */
  daily_usage_average?: CreateInventoryLevelRequestDailyUsageAverage;
  /** The standard deviation of the daily usage of the inventory item */
  daily_usage_standard_deviation?: CreateInventoryLevelRequestDailyUsageStandardDeviation;
  /** Description of the inventory item */
  description?: CreateInventoryLevelRequestDescription;
  /** The average economic lot size of the inventory item */
  economic_lot_size?: CreateInventoryLevelRequestEconomicLotSize;
  /** The lower limit of the inventory item after which inventory is excessive */
  excess_floor?: CreateInventoryLevelRequestExcessFloor;
  /** How many units of the inventory item are in transit */
  in_transit: CreateInventoryLevelRequestInTransit;
  /** Unique identifier of the inventory item */
  inventory_item_id: string;
  /** Do we need this value? */
  lead_time?: CreateInventoryLevelRequestLeadTime;
  /** The average lead time of the inventory item */
  lead_time_average?: CreateInventoryLevelRequestLeadTimeAverage;
  /** The standard deviation of the lead time of the inventory item */
  lead_time_standard_deviation?: CreateInventoryLevelRequestLeadTimeStandardDeviation;
  /** The location of the inventory item within the warehouse */
  location?: CreateInventoryLevelRequestLocation;
  /** The maximum inventory of the inventory item we should have on stock at all times */
  maximum_inventory?: CreateInventoryLevelRequestMaximumInventory;
  /** The minimum inventory of the inventory item we should have on stock at all times */
  minimum_inventory?: CreateInventoryLevelRequestMinimumInventory;
  /** Name of the inventory item */
  name: string;
  /** How many units of the inventory item are ordered */
  ordered: CreateInventoryLevelRequestOrdered;
  /** The lower limit of the inventory item at which a reorder must be made */
  reorder_floor?: CreateInventoryLevelRequestReorderFloor;
  /** How many units of the inventory item are requested */
  requested: CreateInventoryLevelRequestRequested;
  /** How many units of the inventory item are reserved */
  reserved: CreateInventoryLevelRequestReserved;
  /** The safety factor of the inventory item */
  safety_factor?: CreateInventoryLevelRequestSafetyFactor;
  /** How many units of the inventory item are on hand. This is the sum of reserved, assigned, requested, ordered, in_transit, available */
  total: CreateInventoryLevelRequestTotal;
  /** Unique identifier of the warehouse */
  warehouse_id: string;
}
