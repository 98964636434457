import { NavLink as MantineNavLink, ThemeIcon } from '@mantine/core';
import {
  IconAddressBook,
  IconAdjustments,
  IconBuilding,
  IconCategory,
  IconHome,
  IconLock,
  IconMessages,
  IconNotification,
  IconSettings,
  IconUser,
  IconWeight,
} from '@tabler/icons-react';
import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

const links: (ParentLinkProps | MainLinkProps)[] = [
  {
    icon: <IconHome size={16} />,
    color: 'blue',
    label: 'Dashboard',
    to: '/',
  },
  {
    icon: <IconMessages size={16} />,
    color: 'violet',
    label: 'Catalogues',
    links: [
      {
        icon: <IconAddressBook size={16} />,
        color: 'violet',
        label: 'Business Entities',
        to: '/catalogues/business-entities',
      },
      {
        icon: <IconMessages size={16} />,
        color: 'violet',
        label: 'Items',
        to: '/catalogues/items',
      },
    ],
  },
  {
    icon: <IconMessages size={16} />,
    color: 'orange',
    label: 'Inventory',
    links: [
      {
        icon: <IconMessages size={16} />,
        color: 'orange',
        label: 'Items',
        to: '/inventory/items',
      },
    ],
  },
  {
    icon: <IconAdjustments size={16} />,
    color: 'teal',
    label: 'Configuration',
    links: [
      {
        icon: <IconCategory size={16} />,
        color: 'teal',
        label: 'Item Categories',
        to: '/configuration/item-categories',
      },
      {
        icon: <IconWeight size={16} />,
        color: 'teal',
        label: 'Units',
        to: '/configuration/units',
      },
    ],
  },
  {
    icon: <IconSettings size={16} />,
    color: 'gray',
    label: 'Settings',
    links: [
      {
        icon: <IconUser size={16} />,
        color: 'gray',
        label: 'Profile',
        to: '/settings/profile',
      },
      {
        icon: <IconLock size={16} />,
        color: 'gray',
        label: 'Security',
        to: '/settings/security',
      },
      {
        icon: <IconNotification size={16} />,
        color: 'gray',
        label: 'Notifications',
        to: '/settings/notifications',
      },
      {
        icon: <IconBuilding size={16} />,
        color: 'gray',
        label: 'Organization',
        to: '/settings/organization',
      },
    ],
  },
];

function isParent(link: ParentLinkProps | MainLinkProps): link is ParentLinkProps {
  return 'links' in link;
}

interface ParentLinkProps {
  icon: ReactNode;
  color: string;
  label: string;
  links: MainLinkProps[];
}

function ParentLink({ icon, color, label, links }: ParentLinkProps) {
  return (
    <MantineNavLink
      label={label}
      icon={
        <ThemeIcon color={color} variant="light">
          {icon}
        </ThemeIcon>
      }
      color={color}
      childrenOffset={28}
    >
      {links?.map((link) => <MainLink key={link.label} {...link} />)}
    </MantineNavLink>
  );
}

interface MainLinkProps {
  icon: ReactNode;
  color: string;
  label: string;
  to: string;
}

function MainLink({ icon, color, label, to }: MainLinkProps) {
  return (
    <NavLink key={label} to={to}>
      {({ isActive }) => (
        <MantineNavLink
          label={label}
          active={isActive}
          style={{ textDecoration: 'none' }}
          icon={
            <ThemeIcon color={color} variant="light">
              {icon}
            </ThemeIcon>
          }
        />
      )}
    </NavLink>
  );
}

export function MainLinks() {
  return (
    <>
      {links.map((link) =>
        isParent(link) ? (
          <ParentLink key={link.label} {...link} />
        ) : (
          <MainLink key={link.label} {...link} />
        ),
      )}
    </>
  );
}
