import { Button, Group, LoadingOverlay, Select, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { FormErrors } from '@mantine/form/lib/types';
import { useGetItemCategories } from '../../../api';

interface Props<T> {
  onSubmit: (values: T) => void;
  onError: (errors: FormErrors, values: T) => void;
  loading: boolean;
  form: UseFormReturnType<T>;
}

export function Form<T>({ form, onSubmit, onError, loading }: Props<T>) {
  const itemCategories = useGetItemCategories();

  return (
    <form onSubmit={form.onSubmit(onSubmit, onError)}>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <TextInput
        withAsterisk
        label="Name"
        description={'Name of this item.'}
        placeholder="name"
        {...form.getInputProps('name')}
      />
      <Select
        mt="md"
        label="Parent Category"
        required={false}
        searchable
        clearable
        allowDeselect
        disabled={itemCategories.isLoading}
        data={
          itemCategories.data?.map((itemCategory) => ({
            value: itemCategory.id,
            label: itemCategory.name,
          })) ?? ['Loading...']
        }
        {...form.getInputProps('parent_id')}
      />
      <Group position="right" mt="md">
        <Button type="button">Cancel</Button>
        <Button type="submit">Submit</Button>
      </Group>
    </form>
  );
}
