/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { InventoryMovementEntriesPaginationOrderBy } from './inventoryMovementEntriesPaginationOrderBy';
import type { InventoryMovementEntriesPaginationOrderDirection } from './inventoryMovementEntriesPaginationOrderDirection';
import type { InventoryMovementEntriesPaginationPage } from './inventoryMovementEntriesPaginationPage';
import type { InventoryMovementEntriesPaginationPerPage } from './inventoryMovementEntriesPaginationPerPage';

export interface InventoryMovementEntriesPagination {
  /** The field to order by. Defaults to `id` if not specified. */
  order_by?: InventoryMovementEntriesPaginationOrderBy;
  /** The direction to order by. Defaults to `asc` if not specified. */
  order_direction?: InventoryMovementEntriesPaginationOrderDirection;
  /** The page number to return. Defaults to 1 if not specified. */
  page?: InventoryMovementEntriesPaginationPage;
  /** The number of items per page. Defaults to 10 if not specified. */
  per_page?: InventoryMovementEntriesPaginationPerPage;
}
