/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { FinanceDocumentEntryTotalPrice } from './financeDocumentEntryTotalPrice';
import type { FinanceDocumentEntryUnitAmount } from './financeDocumentEntryUnitAmount';
import type { FinanceDocumentEntryUnitPrice } from './financeDocumentEntryUnitPrice';

/**
 * An entry in a finance document. Every finance document has at least one entry. Every item in a finance document is represented as an entry.
 */
export interface FinanceDocumentEntry {
  /** Timestamp of when the inventory item was created */
  created_at: string;
  /** Identifier of the finance document this entry belongs to */
  finance_document_id: string;
  /** Unique identifier of the entry */
  id: string;
  /** Identifier of the item this entry represents */
  item_id: string;
  /** The total price for the entry. It's the multiplication of unit_amount x unit_price */
  total_price: FinanceDocumentEntryTotalPrice;
  /** The amount of items described in this entry */
  unit_amount: FinanceDocumentEntryUnitAmount;
  /** The unitary price for 1 item */
  unit_price: FinanceDocumentEntryUnitPrice;
  /** Timestamp of when the inventory item was last updated */
  updated_at: string;
}
