/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { BusinessEntitiesOrderBy } from './businessEntitiesOrderBy';
import type { BusinessEntitiesOrderDirection } from './businessEntitiesOrderDirection';

export type GetBusinessEntitiesParams = {
  /**
   * The field to order by. Defaults to `id` if not specified.
   */
  order_by?: BusinessEntitiesOrderBy | null;
  /**
   * The direction to order by. Defaults to `asc` if not specified.
   */
  order_direction?: BusinessEntitiesOrderDirection | null;
  /**
   * The page number to return. Defaults to 1 if not specified.
   */
  page?: number | null;
  /**
   * The number of items per page. Defaults to 10 if not specified.
   */
  per_page?: number | null;
  /**
   * Is this business entity a contact?
   */
  is_contact?: boolean | null;
  /**
   * Is this business entity a customer?
   */
  is_customer?: boolean | null;
  /**
   * Is this business entity a supplier?
   */
  is_supplier?: boolean | null;
  /**
   * Is this business entity a warehouse?
   */
  is_warehouse?: boolean | null;
  /**
   * The name of the business entity to search for
   */
  name?: string | null;
};
