import { z } from 'zod';

export const schema = z.object({
  name: z.string().min(3).max(128),
  description: z.string().max(5000).optional().or(z.literal('')),
  item_category_id: z.string().uuid().optional().or(z.literal('')),
  can_be_purchased: z.boolean(),
  can_be_sold: z.boolean(),
  has_inventory: z.boolean(),
  has_lot_number: z.boolean(),
  has_serial_number: z.boolean(),
  is_input: z.boolean(),
  is_perishable: z.boolean(),
  is_product: z.boolean(),
  is_raw_material: z.boolean(),
  is_service: z.boolean(),
});
