import { Avatar } from '@mantine/core';
import { useAuthInfo } from '@propelauth/react';
import React from 'react';
import { Loading } from '../../components/Loading';

export function Security() {
  const auth = useAuthInfo();

  if (auth.loading) {
    return <Loading />;
  }

  if (!auth.user) {
    return <div>User not found</div>;
  }

  return <Avatar size="xl" src={auth.user.pictureUrl} />;
}
