/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { UpdateInventoryLevelRequestAssigned } from './updateInventoryLevelRequestAssigned';
import type { UpdateInventoryLevelRequestAvailable } from './updateInventoryLevelRequestAvailable';
import type { UpdateInventoryLevelRequestCriticalFloor } from './updateInventoryLevelRequestCriticalFloor';
import type { UpdateInventoryLevelRequestDailyUsageAverage } from './updateInventoryLevelRequestDailyUsageAverage';
import type { UpdateInventoryLevelRequestDailyUsageStandardDeviation } from './updateInventoryLevelRequestDailyUsageStandardDeviation';
import type { UpdateInventoryLevelRequestDescription } from './updateInventoryLevelRequestDescription';
import type { UpdateInventoryLevelRequestEconomicLotSize } from './updateInventoryLevelRequestEconomicLotSize';
import type { UpdateInventoryLevelRequestExcessFloor } from './updateInventoryLevelRequestExcessFloor';
import type { UpdateInventoryLevelRequestInTransit } from './updateInventoryLevelRequestInTransit';
import type { UpdateInventoryLevelRequestLeadTime } from './updateInventoryLevelRequestLeadTime';
import type { UpdateInventoryLevelRequestLeadTimeAverage } from './updateInventoryLevelRequestLeadTimeAverage';
import type { UpdateInventoryLevelRequestLeadTimeStandardDeviation } from './updateInventoryLevelRequestLeadTimeStandardDeviation';
import type { UpdateInventoryLevelRequestLocation } from './updateInventoryLevelRequestLocation';
import type { UpdateInventoryLevelRequestMaximumInventory } from './updateInventoryLevelRequestMaximumInventory';
import type { UpdateInventoryLevelRequestMinimumInventory } from './updateInventoryLevelRequestMinimumInventory';
import type { UpdateInventoryLevelRequestOrdered } from './updateInventoryLevelRequestOrdered';
import type { UpdateInventoryLevelRequestReorderFloor } from './updateInventoryLevelRequestReorderFloor';
import type { UpdateInventoryLevelRequestRequested } from './updateInventoryLevelRequestRequested';
import type { UpdateInventoryLevelRequestReserved } from './updateInventoryLevelRequestReserved';
import type { UpdateInventoryLevelRequestSafetyFactor } from './updateInventoryLevelRequestSafetyFactor';
import type { UpdateInventoryLevelRequestTotal } from './updateInventoryLevelRequestTotal';

export interface UpdateInventoryLevelRequest {
  /** How many units of the inventory item are assigned */
  assigned: UpdateInventoryLevelRequestAssigned;
  /** How many units of the inventory item are available */
  available: UpdateInventoryLevelRequestAvailable;
  /** The lower limit of the inventory item at which a critical reorder must be made */
  critical_floor?: UpdateInventoryLevelRequestCriticalFloor;
  /** The average daily usage of the inventory item */
  daily_usage_average?: UpdateInventoryLevelRequestDailyUsageAverage;
  /** The standard deviation of the daily usage of the inventory item */
  daily_usage_standard_deviation?: UpdateInventoryLevelRequestDailyUsageStandardDeviation;
  /** Description of the inventory item */
  description?: UpdateInventoryLevelRequestDescription;
  /** The average economic lot size of the inventory item */
  economic_lot_size?: UpdateInventoryLevelRequestEconomicLotSize;
  /** The lower limit of the inventory item after which inventory is excessive */
  excess_floor?: UpdateInventoryLevelRequestExcessFloor;
  /** How many units of the inventory item are in transit */
  in_transit: UpdateInventoryLevelRequestInTransit;
  /** Unique identifier of the inventory item */
  inventory_item_id: string;
  /** Do we need this value? */
  lead_time?: UpdateInventoryLevelRequestLeadTime;
  /** The average lead time of the inventory item */
  lead_time_average?: UpdateInventoryLevelRequestLeadTimeAverage;
  /** The standard deviation of the lead time of the inventory item */
  lead_time_standard_deviation?: UpdateInventoryLevelRequestLeadTimeStandardDeviation;
  /** The location of the inventory item within the warehouse */
  location?: UpdateInventoryLevelRequestLocation;
  /** The maximum inventory of the inventory item we should have on stock at all times */
  maximum_inventory?: UpdateInventoryLevelRequestMaximumInventory;
  /** The minimum inventory of the inventory item we should have on stock at all times */
  minimum_inventory?: UpdateInventoryLevelRequestMinimumInventory;
  /** Name of the inventory item */
  name: string;
  /** How many units of the inventory item are ordered */
  ordered: UpdateInventoryLevelRequestOrdered;
  /** The lower limit of the inventory item at which a reorder must be made */
  reorder_floor?: UpdateInventoryLevelRequestReorderFloor;
  /** How many units of the inventory item are requested */
  requested: UpdateInventoryLevelRequestRequested;
  /** How many units of the inventory item are reserved */
  reserved: UpdateInventoryLevelRequestReserved;
  /** The safety factor of the inventory item */
  safety_factor?: UpdateInventoryLevelRequestSafetyFactor;
  /** How many units of the inventory item are on hand. This is the sum of reserved, assigned, requested, ordered, in_transit, available */
  total: UpdateInventoryLevelRequestTotal;
  /** Unique identifier of the warehouse */
  warehouse_id: string;
}
