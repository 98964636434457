/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */

export type InventoryLevelsOrderDirection =
  (typeof InventoryLevelsOrderDirection)[keyof typeof InventoryLevelsOrderDirection];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InventoryLevelsOrderDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;
