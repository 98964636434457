/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { UpdateInventoryLevelRequestReorderFloorAnyOf } from './updateInventoryLevelRequestReorderFloorAnyOf';

/**
 * The lower limit of the inventory item at which a reorder must be made
 */
export type UpdateInventoryLevelRequestReorderFloor =
  UpdateInventoryLevelRequestReorderFloorAnyOf | null;
