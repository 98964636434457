/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { CreateItemCategoryRequestParentId } from './createItemCategoryRequestParentId';

/**
 * Payload to create a new item category
 */
export interface CreateItemCategoryRequest {
  /** Name of the item category */
  name: string;
  /** ID of the parent item category */
  parent_id?: CreateItemCategoryRequestParentId;
}
