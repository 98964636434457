/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  InventoryMovement,
  GetInventoryMovementsDefault,
  GetInventoryMovementsParams,
  PostInventoryMovementsDefault,
  CreateInventoryMovementRequest,
  GetInventoryMovementsIdDefault,
  PutInventoryMovementsIdDefault,
  UpdateInventoryMovementRequest,
  DeleteInventoryMovementsIdDefault,
} from '.././model';
import { useCustomInstance } from '.././mutator/custom-instance';
import type { ErrorType } from '.././mutator/custom-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * List all inventory movements
 */
export const useGetInventoryMovementsHook = () => {
  const getInventoryMovements = useCustomInstance<InventoryMovement[]>();

  return (params?: GetInventoryMovementsParams, signal?: AbortSignal) => {
    return getInventoryMovements({ url: `/inventory-movements/`, method: 'get', params, signal });
  };
};

export const getGetInventoryMovementsQueryKey = (params?: GetInventoryMovementsParams) =>
  [`/inventory-movements/`, ...(params ? [params] : [])] as const;

export const useGetInventoryMovementsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementsHook>>>,
  TError = ErrorType<GetInventoryMovementsDefault>,
>(
  params?: GetInventoryMovementsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementsHook>>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInventoryMovementsQueryKey(params);

  const getInventoryMovements = useGetInventoryMovementsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementsHook>>>
  > = ({ signal }) => getInventoryMovements(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetInventoryMovementsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementsHook>>>
>;
export type GetInventoryMovementsQueryError = ErrorType<GetInventoryMovementsDefault>;

export const useGetInventoryMovements = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementsHook>>>,
  TError = ErrorType<GetInventoryMovementsDefault>,
>(
  params?: GetInventoryMovementsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementsHook>>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetInventoryMovementsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create a new inventory movement
 */
export const usePostInventoryMovementsHook = () => {
  const postInventoryMovements = useCustomInstance<InventoryMovement>();

  return (createInventoryMovementRequest: CreateInventoryMovementRequest) => {
    return postInventoryMovements({
      url: `/inventory-movements/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createInventoryMovementRequest,
    });
  };
};

export const usePostInventoryMovementsMutationOptions = <
  TError = ErrorType<PostInventoryMovementsDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostInventoryMovementsHook>>>,
    TError,
    { data: CreateInventoryMovementRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostInventoryMovementsHook>>>,
  TError,
  { data: CreateInventoryMovementRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const postInventoryMovements = usePostInventoryMovementsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostInventoryMovementsHook>>>,
    { data: CreateInventoryMovementRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postInventoryMovements(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostInventoryMovementsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostInventoryMovementsHook>>>
>;
export type PostInventoryMovementsMutationBody = CreateInventoryMovementRequest;
export type PostInventoryMovementsMutationError = ErrorType<PostInventoryMovementsDefault>;

export const usePostInventoryMovements = <
  TError = ErrorType<PostInventoryMovementsDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostInventoryMovementsHook>>>,
    TError,
    { data: CreateInventoryMovementRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePostInventoryMovementsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get a single inventory movement
 */
export const useGetInventoryMovementsIdHook = () => {
  const getInventoryMovementsId = useCustomInstance<InventoryMovement>();

  return (id: string, signal?: AbortSignal) => {
    return getInventoryMovementsId({ url: `/inventory-movements/${id}`, method: 'get', signal });
  };
};

export const getGetInventoryMovementsIdQueryKey = (id: string) =>
  [`/inventory-movements/${id}`] as const;

export const useGetInventoryMovementsIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementsIdHook>>>,
  TError = ErrorType<GetInventoryMovementsIdDefault>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementsIdHook>>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementsIdHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInventoryMovementsIdQueryKey(id);

  const getInventoryMovementsId = useGetInventoryMovementsIdHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementsIdHook>>>
  > = ({ signal }) => getInventoryMovementsId(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetInventoryMovementsIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementsIdHook>>>
>;
export type GetInventoryMovementsIdQueryError = ErrorType<GetInventoryMovementsIdDefault>;

export const useGetInventoryMovementsId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementsIdHook>>>,
  TError = ErrorType<GetInventoryMovementsIdDefault>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementsIdHook>>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetInventoryMovementsIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update a inventory movement
 */
export const usePutInventoryMovementsIdHook = () => {
  const putInventoryMovementsId = useCustomInstance<InventoryMovement>();

  return (id: string, updateInventoryMovementRequest: UpdateInventoryMovementRequest) => {
    return putInventoryMovementsId({
      url: `/inventory-movements/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateInventoryMovementRequest,
    });
  };
};

export const usePutInventoryMovementsIdMutationOptions = <
  TError = ErrorType<PutInventoryMovementsIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutInventoryMovementsIdHook>>>,
    TError,
    { id: string; data: UpdateInventoryMovementRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePutInventoryMovementsIdHook>>>,
  TError,
  { id: string; data: UpdateInventoryMovementRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const putInventoryMovementsId = usePutInventoryMovementsIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePutInventoryMovementsIdHook>>>,
    { id: string; data: UpdateInventoryMovementRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return putInventoryMovementsId(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutInventoryMovementsIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePutInventoryMovementsIdHook>>>
>;
export type PutInventoryMovementsIdMutationBody = UpdateInventoryMovementRequest;
export type PutInventoryMovementsIdMutationError = ErrorType<PutInventoryMovementsIdDefault>;

export const usePutInventoryMovementsId = <
  TError = ErrorType<PutInventoryMovementsIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutInventoryMovementsIdHook>>>,
    TError,
    { id: string; data: UpdateInventoryMovementRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePutInventoryMovementsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Delete a inventory movement
 */
export const useDeleteInventoryMovementsIdHook = () => {
  const deleteInventoryMovementsId = useCustomInstance<InventoryMovement>();

  return (id: string) => {
    return deleteInventoryMovementsId({ url: `/inventory-movements/${id}`, method: 'delete' });
  };
};

export const useDeleteInventoryMovementsIdMutationOptions = <
  TError = ErrorType<DeleteInventoryMovementsIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteInventoryMovementsIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteInventoryMovementsIdHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteInventoryMovementsId = useDeleteInventoryMovementsIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteInventoryMovementsIdHook>>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteInventoryMovementsId(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteInventoryMovementsIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteInventoryMovementsIdHook>>>
>;

export type DeleteInventoryMovementsIdMutationError = ErrorType<DeleteInventoryMovementsIdDefault>;

export const useDeleteInventoryMovementsId = <
  TError = ErrorType<DeleteInventoryMovementsIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteInventoryMovementsIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteInventoryMovementsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
