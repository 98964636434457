import { RedirectToLogin, RequiredAuthProvider } from '@propelauth/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { Loading } from './components/Loading';

const container = document.getElementById('app');
if (container === null) {
  throw new Error('element #app not found');
}

const { REACT_APP_AUTH_URL } = process.env;

if (REACT_APP_AUTH_URL === undefined) {
  throw new Error('REACT_APP_AUTH_URL not defined');
}

function getActiveOrg() {
  return localStorage.getItem('ActiveOrg');
}

const queryClient = new QueryClient();

const root = createRoot(container);
root.render(
  <RequiredAuthProvider
    authUrl={REACT_APP_AUTH_URL}
    displayWhileLoading={<Loading />}
    displayIfLoggedOut={<RedirectToLogin />}
    getActiveOrgFn={getActiveOrg}
  >
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <StrictMode>
          <App />
        </StrictMode>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </BrowserRouter>
  </RequiredAuthProvider>,
);
