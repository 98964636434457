/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { InventoryLevelsOrderBy } from './inventoryLevelsOrderBy';
import type { InventoryLevelsOrderDirection } from './inventoryLevelsOrderDirection';

export type GetInventoryLevelsParams = {
  /**
   * The field to order by. Defaults to `id` if not specified.
   */
  order_by?: InventoryLevelsOrderBy | null;
  /**
   * The direction to order by. Defaults to `asc` if not specified.
   */
  order_direction?: InventoryLevelsOrderDirection | null;
  /**
   * The page number to return. Defaults to 1 if not specified.
   */
  page?: number | null;
  /**
   * The number of items per page. Defaults to 10 if not specified.
   */
  per_page?: number | null;
  /**
   * Unique identifier of the inventory item
   */
  inventory_item_id?: string | null;
  /**
   * Name of the inventory item
   */
  name?: string | null;
  /**
   * Unique identifier of the warehouse
   */
  warehouse_id?: string | null;
};
