/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { InventoryMovementConcept } from './inventoryMovementConcept';
import type { InventoryMovementConditions } from './inventoryMovementConditions';
import type { InventoryMovementDescription } from './inventoryMovementDescription';
import type { InventoryMovementInsurance } from './inventoryMovementInsurance';
import type { InventoryMovementReference } from './inventoryMovementReference';
import type { InventoryMovementTerms } from './inventoryMovementTerms';
import type { InventoryMovementTransport } from './inventoryMovementTransport';

/**
 * An inventory movement is a document with several entries that describe a transfer of inventory items between two business entities
 */
export interface InventoryMovement {
  /** A concept */
  concept?: InventoryMovementConcept;
  /** Delivery conditions */
  conditions?: InventoryMovementConditions;
  /** Timestamp of when the inventory item was created */
  created_at: string;
  /** A short description */
  description?: InventoryMovementDescription;
  /** ID of the destination business entity */
  destination_business_entity_id: string;
  /** Unique identifier */
  id: string;
  /** The insurance details */
  insurance?: InventoryMovementInsurance;
  /** ID of the origin business entity */
  origin_business_entity_id: string;
  /** A reference code */
  reference?: InventoryMovementReference;
  /** Delivery terms */
  terms?: InventoryMovementTerms;
  /** The transport details */
  transport?: InventoryMovementTransport;
  /** Timestamp of when the inventory item was last updated */
  updated_at: string;
}
