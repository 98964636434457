import { z } from 'zod';

export const schema = z.object({
  name: z.string().min(3).max(1024),

  contact_id: z.string().min(3).max(1024).optional().or(z.literal('')),

  is_contact: z.boolean(),
  contact_address: z.string().min(3).max(1024).optional().or(z.literal('')),

  contact_email: z.string().min(3).max(1024).optional().or(z.literal('')),
  contact_phone_number: z.string().min(3).max(1024).optional().or(z.literal('')),

  is_customer: z.boolean(),
  customer_credit_limit: z.number().optional().or(z.literal('')),
  customer_segment: z.string().min(3).max(1024).optional().or(z.literal('')),

  is_supplier: z.boolean(),
  supplier_lead_time_days: z.number().optional().or(z.literal('')),

  supplier_payment_terms: z.string().min(3).max(1024).optional().or(z.literal('')),

  is_warehouse: z.boolean(),
  warehouse_capacity: z.number().optional().or(z.literal('')),
});
