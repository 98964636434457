/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */

/**
 * Identifier of the finance document this entry belongs to
 */
export type FinanceDocumentEntriesFilterFinanceDocumentId = string | null;
