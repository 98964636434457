/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  InventoryMovementEntry,
  GetInventoryMovementEntriesDefault,
  GetInventoryMovementEntriesParams,
  PostInventoryMovementEntriesDefault,
  CreateInventoryMovementEntryRequest,
  GetInventoryMovementEntriesIdDefault,
  PutInventoryMovementEntriesIdDefault,
  UpdateInventoryMovementEntryRequest,
  DeleteInventoryMovementEntriesIdDefault,
} from '.././model';
import { useCustomInstance } from '.././mutator/custom-instance';
import type { ErrorType } from '.././mutator/custom-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * List all inventory movement entries
 */
export const useGetInventoryMovementEntriesHook = () => {
  const getInventoryMovementEntries = useCustomInstance<InventoryMovementEntry[]>();

  return (params?: GetInventoryMovementEntriesParams, signal?: AbortSignal) => {
    return getInventoryMovementEntries({
      url: `/inventory-movement-entries/`,
      method: 'get',
      params,
      signal,
    });
  };
};

export const getGetInventoryMovementEntriesQueryKey = (
  params?: GetInventoryMovementEntriesParams,
) => [`/inventory-movement-entries/`, ...(params ? [params] : [])] as const;

export const useGetInventoryMovementEntriesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementEntriesHook>>>,
  TError = ErrorType<GetInventoryMovementEntriesDefault>,
>(
  params?: GetInventoryMovementEntriesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementEntriesHook>>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementEntriesHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInventoryMovementEntriesQueryKey(params);

  const getInventoryMovementEntries = useGetInventoryMovementEntriesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementEntriesHook>>>
  > = ({ signal }) => getInventoryMovementEntries(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetInventoryMovementEntriesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementEntriesHook>>>
>;
export type GetInventoryMovementEntriesQueryError = ErrorType<GetInventoryMovementEntriesDefault>;

export const useGetInventoryMovementEntries = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementEntriesHook>>>,
  TError = ErrorType<GetInventoryMovementEntriesDefault>,
>(
  params?: GetInventoryMovementEntriesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementEntriesHook>>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetInventoryMovementEntriesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create a new inventory movement entry
 */
export const usePostInventoryMovementEntriesHook = () => {
  const postInventoryMovementEntries = useCustomInstance<InventoryMovementEntry>();

  return (createInventoryMovementEntryRequest: CreateInventoryMovementEntryRequest) => {
    return postInventoryMovementEntries({
      url: `/inventory-movement-entries/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createInventoryMovementEntryRequest,
    });
  };
};

export const usePostInventoryMovementEntriesMutationOptions = <
  TError = ErrorType<PostInventoryMovementEntriesDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostInventoryMovementEntriesHook>>>,
    TError,
    { data: CreateInventoryMovementEntryRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostInventoryMovementEntriesHook>>>,
  TError,
  { data: CreateInventoryMovementEntryRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const postInventoryMovementEntries = usePostInventoryMovementEntriesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostInventoryMovementEntriesHook>>>,
    { data: CreateInventoryMovementEntryRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postInventoryMovementEntries(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostInventoryMovementEntriesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostInventoryMovementEntriesHook>>>
>;
export type PostInventoryMovementEntriesMutationBody = CreateInventoryMovementEntryRequest;
export type PostInventoryMovementEntriesMutationError =
  ErrorType<PostInventoryMovementEntriesDefault>;

export const usePostInventoryMovementEntries = <
  TError = ErrorType<PostInventoryMovementEntriesDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostInventoryMovementEntriesHook>>>,
    TError,
    { data: CreateInventoryMovementEntryRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePostInventoryMovementEntriesMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get a single inventory movement entry
 */
export const useGetInventoryMovementEntriesIdHook = () => {
  const getInventoryMovementEntriesId = useCustomInstance<InventoryMovementEntry>();

  return (id: string, signal?: AbortSignal) => {
    return getInventoryMovementEntriesId({
      url: `/inventory-movement-entries/${id}`,
      method: 'get',
      signal,
    });
  };
};

export const getGetInventoryMovementEntriesIdQueryKey = (id: string) =>
  [`/inventory-movement-entries/${id}`] as const;

export const useGetInventoryMovementEntriesIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementEntriesIdHook>>>,
  TError = ErrorType<GetInventoryMovementEntriesIdDefault>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementEntriesIdHook>>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementEntriesIdHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInventoryMovementEntriesIdQueryKey(id);

  const getInventoryMovementEntriesId = useGetInventoryMovementEntriesIdHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementEntriesIdHook>>>
  > = ({ signal }) => getInventoryMovementEntriesId(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetInventoryMovementEntriesIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementEntriesIdHook>>>
>;
export type GetInventoryMovementEntriesIdQueryError =
  ErrorType<GetInventoryMovementEntriesIdDefault>;

export const useGetInventoryMovementEntriesId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementEntriesIdHook>>>,
  TError = ErrorType<GetInventoryMovementEntriesIdDefault>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetInventoryMovementEntriesIdHook>>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetInventoryMovementEntriesIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update an inventory movement entry
 */
export const usePutInventoryMovementEntriesIdHook = () => {
  const putInventoryMovementEntriesId = useCustomInstance<InventoryMovementEntry>();

  return (id: string, updateInventoryMovementEntryRequest: UpdateInventoryMovementEntryRequest) => {
    return putInventoryMovementEntriesId({
      url: `/inventory-movement-entries/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateInventoryMovementEntryRequest,
    });
  };
};

export const usePutInventoryMovementEntriesIdMutationOptions = <
  TError = ErrorType<PutInventoryMovementEntriesIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutInventoryMovementEntriesIdHook>>>,
    TError,
    { id: string; data: UpdateInventoryMovementEntryRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePutInventoryMovementEntriesIdHook>>>,
  TError,
  { id: string; data: UpdateInventoryMovementEntryRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const putInventoryMovementEntriesId = usePutInventoryMovementEntriesIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePutInventoryMovementEntriesIdHook>>>,
    { id: string; data: UpdateInventoryMovementEntryRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return putInventoryMovementEntriesId(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutInventoryMovementEntriesIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePutInventoryMovementEntriesIdHook>>>
>;
export type PutInventoryMovementEntriesIdMutationBody = UpdateInventoryMovementEntryRequest;
export type PutInventoryMovementEntriesIdMutationError =
  ErrorType<PutInventoryMovementEntriesIdDefault>;

export const usePutInventoryMovementEntriesId = <
  TError = ErrorType<PutInventoryMovementEntriesIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutInventoryMovementEntriesIdHook>>>,
    TError,
    { id: string; data: UpdateInventoryMovementEntryRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePutInventoryMovementEntriesIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Delete an inventory movement entry
 */
export const useDeleteInventoryMovementEntriesIdHook = () => {
  const deleteInventoryMovementEntriesId = useCustomInstance<InventoryMovementEntry>();

  return (id: string) => {
    return deleteInventoryMovementEntriesId({
      url: `/inventory-movement-entries/${id}`,
      method: 'delete',
    });
  };
};

export const useDeleteInventoryMovementEntriesIdMutationOptions = <
  TError = ErrorType<DeleteInventoryMovementEntriesIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteInventoryMovementEntriesIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteInventoryMovementEntriesIdHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteInventoryMovementEntriesId = useDeleteInventoryMovementEntriesIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteInventoryMovementEntriesIdHook>>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteInventoryMovementEntriesId(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteInventoryMovementEntriesIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteInventoryMovementEntriesIdHook>>>
>;

export type DeleteInventoryMovementEntriesIdMutationError =
  ErrorType<DeleteInventoryMovementEntriesIdDefault>;

export const useDeleteInventoryMovementEntriesId = <
  TError = ErrorType<DeleteInventoryMovementEntriesIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteInventoryMovementEntriesIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteInventoryMovementEntriesIdMutationOptions(options);

  return useMutation(mutationOptions);
};
