import { ColorScheme, ColorSchemeProvider, MantineProvider } from '@mantine/core';
import { useActiveOrg } from '@propelauth/react';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Catalogues } from './screens/Catalogues';
import { Configuration } from './screens/Configuration';
import { Inventory } from './screens/Inventory';
import { Settings } from './screens/Settings';
import { SelectOrg } from './SelectOrg';

export function App() {
  const [colorScheme, setColorScheme] = useState<ColorScheme>('dark');
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value ?? (colorScheme === 'dark' ? 'light' : 'dark'));

  const org = useActiveOrg();
  if (!org) {
    return <SelectOrg />;
  }

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          colorScheme,
          globalStyles: () => ({
            a: { textDecoration: 'none' },
          }),
        }}
      >
        <Layout>
          <Routes>
            <Route path="/" element={<h1>Home</h1>} />
            <Route path="/catalogues/*" element={<Catalogues />} />
            <Route path="/inventory/*" element={<Inventory />} />
            <Route path="/settings/*" element={<Settings />} />
            <Route path="/configuration/*" element={<Configuration />} />
          </Routes>
        </Layout>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}
