/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  Unit,
  GetUnitsDefault,
  GetUnitsParams,
  PostUnitsDefault,
  CreateUnitRequest,
  GetUnitsIdDefault,
  PutUnitsIdDefault,
  UpdateUnitRequest,
  DeleteUnitsIdDefault,
} from '.././model';
import { useCustomInstance } from '.././mutator/custom-instance';
import type { ErrorType } from '.././mutator/custom-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * List all units
 */
export const useGetUnitsHook = () => {
  const getUnits = useCustomInstance<Unit[]>();

  return (params?: GetUnitsParams, signal?: AbortSignal) => {
    return getUnits({ url: `/units/`, method: 'get', params, signal });
  };
};

export const getGetUnitsQueryKey = (params?: GetUnitsParams) =>
  [`/units/`, ...(params ? [params] : [])] as const;

export const useGetUnitsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUnitsHook>>>,
  TError = ErrorType<GetUnitsDefault>,
>(
  params?: GetUnitsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUnitsHook>>>, TError, TData>;
  },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUnitsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUnitsQueryKey(params);

  const getUnits = useGetUnitsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUnitsHook>>>> = ({
    signal,
  }) => getUnits(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetUnitsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUnitsHook>>>
>;
export type GetUnitsQueryError = ErrorType<GetUnitsDefault>;

export const useGetUnits = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUnitsHook>>>,
  TError = ErrorType<GetUnitsDefault>,
>(
  params?: GetUnitsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUnitsHook>>>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUnitsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create a new unit
 */
export const usePostUnitsHook = () => {
  const postUnits = useCustomInstance<Unit>();

  return (createUnitRequest: CreateUnitRequest) => {
    return postUnits({
      url: `/units/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createUnitRequest,
    });
  };
};

export const usePostUnitsMutationOptions = <
  TError = ErrorType<PostUnitsDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostUnitsHook>>>,
    TError,
    { data: CreateUnitRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostUnitsHook>>>,
  TError,
  { data: CreateUnitRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const postUnits = usePostUnitsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostUnitsHook>>>,
    { data: CreateUnitRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postUnits(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUnitsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostUnitsHook>>>
>;
export type PostUnitsMutationBody = CreateUnitRequest;
export type PostUnitsMutationError = ErrorType<PostUnitsDefault>;

export const usePostUnits = <TError = ErrorType<PostUnitsDefault>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostUnitsHook>>>,
    TError,
    { data: CreateUnitRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePostUnitsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get a single unit
 */
export const useGetUnitsIdHook = () => {
  const getUnitsId = useCustomInstance<Unit>();

  return (id: string, signal?: AbortSignal) => {
    return getUnitsId({ url: `/units/${id}`, method: 'get', signal });
  };
};

export const getGetUnitsIdQueryKey = (id: string) => [`/units/${id}`] as const;

export const useGetUnitsIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUnitsIdHook>>>,
  TError = ErrorType<GetUnitsIdDefault>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUnitsIdHook>>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUnitsIdHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUnitsIdQueryKey(id);

  const getUnitsId = useGetUnitsIdHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUnitsIdHook>>>> = ({
    signal,
  }) => getUnitsId(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetUnitsIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUnitsIdHook>>>
>;
export type GetUnitsIdQueryError = ErrorType<GetUnitsIdDefault>;

export const useGetUnitsId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUnitsIdHook>>>,
  TError = ErrorType<GetUnitsIdDefault>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUnitsIdHook>>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUnitsIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update an unit
 */
export const usePutUnitsIdHook = () => {
  const putUnitsId = useCustomInstance<Unit>();

  return (id: string, updateUnitRequest: UpdateUnitRequest) => {
    return putUnitsId({
      url: `/units/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateUnitRequest,
    });
  };
};

export const usePutUnitsIdMutationOptions = <
  TError = ErrorType<PutUnitsIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutUnitsIdHook>>>,
    TError,
    { id: string; data: UpdateUnitRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePutUnitsIdHook>>>,
  TError,
  { id: string; data: UpdateUnitRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const putUnitsId = usePutUnitsIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePutUnitsIdHook>>>,
    { id: string; data: UpdateUnitRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return putUnitsId(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutUnitsIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePutUnitsIdHook>>>
>;
export type PutUnitsIdMutationBody = UpdateUnitRequest;
export type PutUnitsIdMutationError = ErrorType<PutUnitsIdDefault>;

export const usePutUnitsId = <TError = ErrorType<PutUnitsIdDefault>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutUnitsIdHook>>>,
    TError,
    { id: string; data: UpdateUnitRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePutUnitsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Delete an unit
 */
export const useDeleteUnitsIdHook = () => {
  const deleteUnitsId = useCustomInstance<Unit>();

  return (id: string) => {
    return deleteUnitsId({ url: `/units/${id}`, method: 'delete' });
  };
};

export const useDeleteUnitsIdMutationOptions = <
  TError = ErrorType<DeleteUnitsIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteUnitsIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteUnitsIdHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteUnitsId = useDeleteUnitsIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteUnitsIdHook>>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteUnitsId(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUnitsIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteUnitsIdHook>>>
>;

export type DeleteUnitsIdMutationError = ErrorType<DeleteUnitsIdDefault>;

export const useDeleteUnitsId = <
  TError = ErrorType<DeleteUnitsIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteUnitsIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteUnitsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
