import { useActiveOrg, useAuthInfo } from '@propelauth/react';
import Axios, { AxiosError, AxiosRequestConfig } from 'axios';

const { API_URL } = process.env;

if (API_URL === undefined) {
  throw new Error('API_URL not defined');
}

export const AXIOS_INSTANCE = Axios.create({ baseURL: API_URL });

export const useCustomInstance = <T>(): ((
  config: AxiosRequestConfig,
) => Promise<T>) => {
  const auth = useAuthInfo();
  const org = useActiveOrg();

  const authorization =
    !auth.loading && auth.accessToken
      ? `Bearer ${auth.accessToken}`
      : undefined;

  return (config: AxiosRequestConfig) => {
    const source = Axios.CancelToken.source();
    const promise = AXIOS_INSTANCE<T>({
      ...config,
      headers: {
        Authorization: authorization,
        'X-Org-Id': org?.orgId,
        ...config.headers,
      },
      cancelToken: source.token,
    }).then(({ data }) => data);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    promise.cancel = () => {
      source.cancel('Query was cancelled by React Query');
    };

    return promise;
  };
};

export default useCustomInstance;

export type ErrorType<Error> = AxiosError<Error>;
