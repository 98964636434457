/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { CreateBusinessEntityRequestContactAddress } from './createBusinessEntityRequestContactAddress';
import type { CreateBusinessEntityRequestContactEmail } from './createBusinessEntityRequestContactEmail';
import type { CreateBusinessEntityRequestContactId } from './createBusinessEntityRequestContactId';
import type { CreateBusinessEntityRequestContactPhoneNumber } from './createBusinessEntityRequestContactPhoneNumber';
import type { CreateBusinessEntityRequestCustomerCreditLimit } from './createBusinessEntityRequestCustomerCreditLimit';
import type { CreateBusinessEntityRequestCustomerSegment } from './createBusinessEntityRequestCustomerSegment';
import type { CreateBusinessEntityRequestSupplierLeadTimeDays } from './createBusinessEntityRequestSupplierLeadTimeDays';
import type { CreateBusinessEntityRequestSupplierPaymentTerms } from './createBusinessEntityRequestSupplierPaymentTerms';
import type { CreateBusinessEntityRequestWarehouseCapacity } from './createBusinessEntityRequestWarehouseCapacity';

export interface CreateBusinessEntityRequest {
  /** The address of the business entity when it is a contact */
  contact_address?: CreateBusinessEntityRequestContactAddress;
  /** The email of the business entity when it is a contact */
  contact_email?: CreateBusinessEntityRequestContactEmail;
  /** The contact id of the business entity */
  contact_id?: CreateBusinessEntityRequestContactId;
  /** The phone number of the business entity when it is a contact */
  contact_phone_number?: CreateBusinessEntityRequestContactPhoneNumber;
  /** The credit limit of the business entity when it is a customer */
  customer_credit_limit?: CreateBusinessEntityRequestCustomerCreditLimit;
  /** The segment of the business entity when it is a customer */
  customer_segment?: CreateBusinessEntityRequestCustomerSegment;
  /** Is this business entity a contact? */
  is_contact: boolean;
  /** Is this business entity a customer? */
  is_customer: boolean;
  /** Is this business entity a supplier? */
  is_supplier: boolean;
  /** Is this business entity a warehouse? */
  is_warehouse: boolean;
  /** Name of the business entity */
  name: string;
  /** The lead time in days of the business entity when it is a supplier */
  supplier_lead_time_days?: CreateBusinessEntityRequestSupplierLeadTimeDays;
  /** The payment terms of the business entity when it is a supplier */
  supplier_payment_terms?: CreateBusinessEntityRequestSupplierPaymentTerms;
  /** The capacity of the business entity when it is a warehouse */
  warehouse_capacity?: CreateBusinessEntityRequestWarehouseCapacity;
}
