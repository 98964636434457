/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { ItemCategoryParentId } from './itemCategoryParentId';

/**
 * Represents a grouping of items within
 */
export interface ItemCategory {
  /** When the item category was created Timestamp of when the inventory item was created */
  created_at: string;
  /** Unique identifier for the item category */
  id: string;
  /** Name of the item category */
  name: string;
  /** ID of the parent item category */
  parent_id?: ItemCategoryParentId;
  /** Timestamp of when the inventory item was last updated */
  updated_at: string;
}
