import { Route, Routes } from 'react-router-dom';
import { Profile } from './Profile';
import { Security } from './Security';

export function Settings() {
  return (
    <Routes>
      <Route path="profile" element={<Profile />} />
      <Route path="security" element={<Security />} />
      <Route path="notifications" element={<Profile />} />
    </Routes>
  );
}
