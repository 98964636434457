/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { UpdateInventoryItemRequestBarcode } from './updateInventoryItemRequestBarcode';
import type { UpdateInventoryItemRequestDefaultDeliveryWarehouseId } from './updateInventoryItemRequestDefaultDeliveryWarehouseId';
import type { UpdateInventoryItemRequestDefaultReceptionWarehouseId } from './updateInventoryItemRequestDefaultReceptionWarehouseId';
import type { UpdateInventoryItemRequestSku } from './updateInventoryItemRequestSku';
import type { UpdateInventoryItemRequestSupplierId } from './updateInventoryItemRequestSupplierId';
import type { UpdateInventoryItemRequestUnitId } from './updateInventoryItemRequestUnitId';

export interface UpdateInventoryItemRequest {
  barcode?: UpdateInventoryItemRequestBarcode;
  default_delivery_warehouse_id?: UpdateInventoryItemRequestDefaultDeliveryWarehouseId;
  default_reception_warehouse_id?: UpdateInventoryItemRequestDefaultReceptionWarehouseId;
  item_id: string;
  sku?: UpdateInventoryItemRequestSku;
  supplier_id?: UpdateInventoryItemRequestSupplierId;
  unit_id?: UpdateInventoryItemRequestUnitId;
}
