/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { InventoryMovementEntriesOrderBy } from './inventoryMovementEntriesOrderBy';
import type { InventoryMovementEntriesOrderDirection } from './inventoryMovementEntriesOrderDirection';

export type GetInventoryMovementEntriesParams = {
  /**
   * The field to order by. Defaults to `id` if not specified.
   */
  order_by?: InventoryMovementEntriesOrderBy | null;
  /**
   * The direction to order by. Defaults to `asc` if not specified.
   */
  order_direction?: InventoryMovementEntriesOrderDirection | null;
  /**
   * The page number to return. Defaults to 1 if not specified.
   */
  page?: number | null;
  /**
   * The number of items per page. Defaults to 10 if not specified.
   */
  per_page?: number | null;
  /**
   * ID of the origin business entity
   */
  inventory_item_id?: string | null;
  /**
   * ID of the destination business entity
   */
  inventory_movement_id?: string | null;
  /**
   * A reference code
   */
  reference?: string | null;
};
