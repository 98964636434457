/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { UnitsPaginationOrderBy } from './unitsPaginationOrderBy';
import type { UnitsPaginationOrderDirection } from './unitsPaginationOrderDirection';
import type { UnitsPaginationPage } from './unitsPaginationPage';
import type { UnitsPaginationPerPage } from './unitsPaginationPerPage';

export interface UnitsPagination {
  order_by?: UnitsPaginationOrderBy;
  order_direction?: UnitsPaginationOrderDirection;
  page?: UnitsPaginationPage;
  per_page?: UnitsPaginationPerPage;
}
