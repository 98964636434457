/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { UpdateBusinessEntityRequestContactAddress } from './updateBusinessEntityRequestContactAddress';
import type { UpdateBusinessEntityRequestContactEmail } from './updateBusinessEntityRequestContactEmail';
import type { UpdateBusinessEntityRequestContactId } from './updateBusinessEntityRequestContactId';
import type { UpdateBusinessEntityRequestContactPhoneNumber } from './updateBusinessEntityRequestContactPhoneNumber';
import type { UpdateBusinessEntityRequestCustomerCreditLimit } from './updateBusinessEntityRequestCustomerCreditLimit';
import type { UpdateBusinessEntityRequestCustomerSegment } from './updateBusinessEntityRequestCustomerSegment';
import type { UpdateBusinessEntityRequestSupplierLeadTimeDays } from './updateBusinessEntityRequestSupplierLeadTimeDays';
import type { UpdateBusinessEntityRequestSupplierPaymentTerms } from './updateBusinessEntityRequestSupplierPaymentTerms';
import type { UpdateBusinessEntityRequestWarehouseCapacity } from './updateBusinessEntityRequestWarehouseCapacity';

export interface UpdateBusinessEntityRequest {
  /** The address of the business entity when it is a contact */
  contact_address?: UpdateBusinessEntityRequestContactAddress;
  /** The email of the business entity when it is a contact */
  contact_email?: UpdateBusinessEntityRequestContactEmail;
  /** The contact id of the business entity */
  contact_id?: UpdateBusinessEntityRequestContactId;
  /** The phone number of the business entity when it is a contact */
  contact_phone_number?: UpdateBusinessEntityRequestContactPhoneNumber;
  /** The credit limit of the business entity when it is a customer */
  customer_credit_limit?: UpdateBusinessEntityRequestCustomerCreditLimit;
  /** The segment of the business entity when it is a customer */
  customer_segment?: UpdateBusinessEntityRequestCustomerSegment;
  /** Is this business entity a contact? */
  is_contact: boolean;
  /** Is this business entity a customer? */
  is_customer: boolean;
  /** Is this business entity a supplier? */
  is_supplier: boolean;
  /** Is this business entity a warehouse? */
  is_warehouse: boolean;
  /** Name of the business entity */
  name: string;
  /** The lead time in days of the business entity when it is a supplier */
  supplier_lead_time_days?: UpdateBusinessEntityRequestSupplierLeadTimeDays;
  /** The payment terms of the business entity when it is a supplier */
  supplier_payment_terms?: UpdateBusinessEntityRequestSupplierPaymentTerms;
  /** The capacity of the business entity when it is a warehouse */
  warehouse_capacity?: UpdateBusinessEntityRequestWarehouseCapacity;
}
