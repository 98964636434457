/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { UpdateItemRequestDescription } from './updateItemRequestDescription';
import type { UpdateItemRequestItemCategoryId } from './updateItemRequestItemCategoryId';

export interface UpdateItemRequest {
  can_be_purchased: boolean;
  can_be_sold: boolean;
  description?: UpdateItemRequestDescription;
  has_inventory: boolean;
  has_lot_number: boolean;
  has_serial_number: boolean;
  is_input: boolean;
  is_perishable: boolean;
  is_product: boolean;
  is_raw_material: boolean;
  is_service: boolean;
  item_category_id?: UpdateItemRequestItemCategoryId;
  name: string;
}
