/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { UnitsFilterCanBePurchased } from './unitsFilterCanBePurchased';
import type { UnitsFilterCanBeSold } from './unitsFilterCanBeSold';
import type { UnitsFilterHasInventory } from './unitsFilterHasInventory';
import type { UnitsFilterHasLotNumber } from './unitsFilterHasLotNumber';
import type { UnitsFilterHasSerialNumber } from './unitsFilterHasSerialNumber';
import type { UnitsFilterIsInput } from './unitsFilterIsInput';
import type { UnitsFilterIsPerishable } from './unitsFilterIsPerishable';
import type { UnitsFilterIsProduct } from './unitsFilterIsProduct';
import type { UnitsFilterIsRawMaterial } from './unitsFilterIsRawMaterial';
import type { UnitsFilterIsService } from './unitsFilterIsService';
import type { UnitsFilterName } from './unitsFilterName';
import type { UnitsFilterUnitCategoryId } from './unitsFilterUnitCategoryId';

export interface UnitsFilter {
  can_be_purchased?: UnitsFilterCanBePurchased;
  can_be_sold?: UnitsFilterCanBeSold;
  has_inventory?: UnitsFilterHasInventory;
  has_lot_number?: UnitsFilterHasLotNumber;
  has_serial_number?: UnitsFilterHasSerialNumber;
  is_input?: UnitsFilterIsInput;
  is_perishable?: UnitsFilterIsPerishable;
  is_product?: UnitsFilterIsProduct;
  is_raw_material?: UnitsFilterIsRawMaterial;
  is_service?: UnitsFilterIsService;
  name?: UnitsFilterName;
  unit_category_id?: UnitsFilterUnitCategoryId;
}
