/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */

export type FinanceDocumentsOrderBy =
  (typeof FinanceDocumentsOrderBy)[keyof typeof FinanceDocumentsOrderBy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FinanceDocumentsOrderBy = {
  id: 'id',
  created_at: 'created_at',
  document_type: 'document_type',
  business_entity_id: 'business_entity_id',
  date: 'date',
  total_amount: 'total_amount',
  status: 'status',
} as const;
