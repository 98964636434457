/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { CreateInventoryItemRequestBarcode } from './createInventoryItemRequestBarcode';
import type { CreateInventoryItemRequestDefaultDeliveryWarehouseId } from './createInventoryItemRequestDefaultDeliveryWarehouseId';
import type { CreateInventoryItemRequestDefaultReceptionWarehouseId } from './createInventoryItemRequestDefaultReceptionWarehouseId';
import type { CreateInventoryItemRequestSku } from './createInventoryItemRequestSku';
import type { CreateInventoryItemRequestSupplierId } from './createInventoryItemRequestSupplierId';
import type { CreateInventoryItemRequestUnitId } from './createInventoryItemRequestUnitId';

export interface CreateInventoryItemRequest {
  barcode?: CreateInventoryItemRequestBarcode;
  default_delivery_warehouse_id?: CreateInventoryItemRequestDefaultDeliveryWarehouseId;
  default_reception_warehouse_id?: CreateInventoryItemRequestDefaultReceptionWarehouseId;
  item_id: string;
  sku?: CreateInventoryItemRequestSku;
  supplier_id?: CreateInventoryItemRequestSupplierId;
  unit_id?: CreateInventoryItemRequestUnitId;
}
