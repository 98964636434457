import { Button, Group, LoadingOverlay, Select, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { FormErrors } from '@mantine/form/lib/types';
import { Link } from 'react-router-dom';
import { useGetBusinessEntities, useGetItems, useGetUnits } from '../../../api';

interface Props<T> {
  onSubmit: (values: T) => void;
  onError: (errors: FormErrors, values: T) => void;
  loading: boolean;
  form: UseFormReturnType<T>;
}

export function Form<T>({ form, onSubmit, onError, loading }: Props<T>) {
  const items = useGetItems({ has_inventory: true });
  const units = useGetUnits();
  const suppliers = useGetBusinessEntities({ is_supplier: true });
  const warehouses = useGetBusinessEntities({ is_warehouse: true });

  return (
    <form onSubmit={form.onSubmit(onSubmit, onError)}>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Select
        withAsterisk
        mt="md"
        label="Item"
        description={
          <Link to="/catalogues/items/new" target="_blank">
            Create a new item
          </Link>
        }
        searchable
        disabled={items.isLoading}
        data={
          items.data?.map((item) => ({
            value: item.id,
            label: item.name,
          })) ?? ['Loading...']
        }
        {...form.getInputProps('item_id')}
      />
      <TextInput
        mt="md"
        label="SKU"
        description="Stock Keeping Unit"
        placeholder="sku"
        {...form.getInputProps('sku')}
      />
      <TextInput
        mt="md"
        label="Barcode"
        description="Barcode"
        placeholder="barcode"
        {...form.getInputProps('barcode')}
      />

      <Select
        mt="md"
        label="Unit"
        description={
          <Link to="/configuration/units/new" target="_blank">
            Create a new unit
          </Link>
        }
        searchable
        clearable
        allowDeselect
        disabled={items.isLoading}
        data={
          units.data?.map((unit) => ({
            value: unit.id,
            label: unit.name,
          })) ?? ['Loading...']
        }
        {...form.getInputProps('unit_id')}
      />
      <Select
        mt="md"
        label="Supplier"
        description={
          <Link to="/catalogues/business-entities/new" target="_blank">
            Create a new supplier
          </Link>
        }
        searchable
        clearable
        allowDeselect
        disabled={items.isLoading}
        data={
          suppliers.data?.map((supplier) => ({
            value: supplier.id,
            label: supplier.name,
          })) ?? ['Loading...']
        }
        {...form.getInputProps('supplier_id')}
      />
      <Select
        mt="md"
        label="Default Reception Warehouse"
        description={
          <Link to="/catalogues/business-entities/new" target="_blank">
            Create a new warehouse
          </Link>
        }
        searchable
        clearable
        allowDeselect
        disabled={items.isLoading}
        data={
          warehouses.data?.map((warehouse) => ({
            value: warehouse.id,
            label: warehouse.name,
          })) ?? ['Loading...']
        }
        {...form.getInputProps('default_reception_warehouse_id')}
      />
      <Select
        mt="md"
        label="Default Delivery Warehouse"
        description={
          <Link to="/catalogues/business-entities/new" target="_blank">
            Create a new warehouse
          </Link>
        }
        searchable
        clearable
        allowDeselect
        disabled={items.isLoading}
        data={
          warehouses.data?.map((warehouse) => ({
            value: warehouse.id,
            label: warehouse.name,
          })) ?? ['Loading...']
        }
        {...form.getInputProps('default_delivery_warehouse_id')}
      />
      <Group position="right" mt="md">
        <Button type="button">Cancel</Button>
        <Button type="submit">Submit</Button>
      </Group>
    </form>
  );
}
