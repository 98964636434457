/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { FinanceDocumentEntriesOrderBy } from './financeDocumentEntriesOrderBy';
import type { FinanceDocumentEntriesOrderDirection } from './financeDocumentEntriesOrderDirection';

export type GetFinanceDocumentEntriesParams = {
  /**
   * The field to order by. Defaults to `id` if not specified.
   */
  order_by?: FinanceDocumentEntriesOrderBy | null;
  /**
   * The direction to order by. Defaults to `asc` if not specified.
   */
  order_direction?: FinanceDocumentEntriesOrderDirection | null;
  /**
   * The page number to return. Defaults to 1 if not specified.
   */
  page?: number | null;
  /**
   * The number of items per page. Defaults to 10 if not specified.
   */
  per_page?: number | null;
  /**
   * Identifier of the finance document this entry belongs to
   */
  finance_document_id?: string | null;
  /**
   * Identifier of the item this entry represents
   */
  item_id?: string | null;
};
