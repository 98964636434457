/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { InventoryMovementsOrderBy } from './inventoryMovementsOrderBy';
import type { InventoryMovementsOrderDirection } from './inventoryMovementsOrderDirection';

export type GetInventoryMovementsParams = {
  /**
   * The field to order by. Defaults to `id` if not specified.
   */
  order_by?: InventoryMovementsOrderBy | null;
  /**
   * The direction to order by. Defaults to `asc` if not specified.
   */
  order_direction?: InventoryMovementsOrderDirection | null;
  /**
   * The page number to return. Defaults to 1 if not specified.
   */
  page?: number | null;
  /**
   * The number of items per page. Defaults to 10 if not specified.
   */
  per_page?: number | null;
  /**
   * ID of the destination business entity
   */
  destination_business_entity_id?: string | null;
  /**
   * ID of the origin business entity
   */
  origin_business_entity_id?: string | null;
  /**
   * A reference code
   */
  reference?: string | null;
};
