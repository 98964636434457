/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { ItemDescription } from './itemDescription';
import type { ItemItemCategoryId } from './itemItemCategoryId';

export interface Item {
  can_be_purchased: boolean;
  can_be_sold: boolean;
  /** Timestamp of when the inventory item was created */
  created_at: string;
  /** Item's description */
  description?: ItemDescription;
  has_inventory: boolean;
  has_lot_number: boolean;
  has_serial_number: boolean;
  /** Unique identifier of the item */
  id: string;
  /** Whether the item is an input in a production line */
  is_input: boolean;
  is_perishable: boolean;
  /** Whether the item is a product or not */
  is_product: boolean;
  /** Whether the item is a raw material */
  is_raw_material: boolean;
  /** Whether the item is a service or not */
  is_service: boolean;
  /** Category the items belongs to */
  item_category_id?: ItemItemCategoryId;
  /** Name of the item */
  name: string;
  /** Timestamp of when the inventory item was last updated */
  updated_at: string;
}
