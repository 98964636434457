import { useForm, zodResolver } from '@mantine/form';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateBusinessEntityRequest, usePostBusinessEntities } from '../../../api';
import { sanitizePayload } from '../../../utils';
import { Form } from './Form';
import { schema } from './schema';

export function New() {
  const navigate = useNavigate();

  const createBusinessEntityMutation = usePostBusinessEntities({
    mutation: {
      onSuccess: () => navigate('..'),
      onError: console.error,
    },
  });

  const handleSubmit = useCallback(
    (values: CreateBusinessEntityRequest) => {
      return createBusinessEntityMutation.mutate({ data: values });
    },
    [createBusinessEntityMutation.mutate],
  );

  const defaultValues = useMemo<CreateBusinessEntityRequest>(
    () => ({
      contact_address: '',
      contact_email: '',
      contact_id: '',
      contact_phone_number: '',
      customer_credit_limit: 0,
      customer_segment: '',
      is_contact: false,
      is_customer: false,
      is_supplier: false,
      is_warehouse: false,
      name: '',
      supplier_lead_time_days: 0,
      supplier_payment_terms: '',
      warehouse_capacity: 0,
    }),
    [],
  );

  const form = useForm<CreateBusinessEntityRequest>({
    initialValues: defaultValues,
    validate: zodResolver(schema),
    validateInputOnBlur: true,
    transformValues: sanitizePayload,
  });

  return (
    <>
      <h1>New Business Entity</h1>
      <Form
        onSubmit={handleSubmit}
        onError={console.error}
        loading={createBusinessEntityMutation.isLoading}
        form={form}
      />
    </>
  );
}
