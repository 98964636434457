/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { CreateInventoryLevelRequestMaximumInventoryAnyOf } from './createInventoryLevelRequestMaximumInventoryAnyOf';

/**
 * The maximum inventory of the inventory item we should have on stock at all times
 */
export type CreateInventoryLevelRequestMaximumInventory =
  CreateInventoryLevelRequestMaximumInventoryAnyOf | null;
