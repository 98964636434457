import { Anchor, Button, Group, Table, Title, Tooltip } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { useGetItemCategories } from '../../../api';
import { Loading } from '../../../components/Loading';

export function List() {
  const itemCategories = useGetItemCategories();

  if (itemCategories.isLoading) return <Loading />;
  if (itemCategories.isError) return <h1>{itemCategories.error?.message}</h1>;

  return (
    <div>
      <Group position={'apart'} p={'md'}>
        <Title>Item Categories</Title>
        <Tooltip label="Add Item Category">
          <Link to={'new'}>
            <Button variant="outline">
              <IconPlus size={20} />
            </Button>
          </Link>
        </Tooltip>
      </Group>
      <Table striped>
        <thead>
          <tr>
            <th scope="col">Name</th>
          </tr>
        </thead>
        <tbody>
          {itemCategories.data.map((item) => (
            <tr key={item.id}>
              <td>
                <Anchor component={Link} to={item.id}>
                  {item.name}
                </Anchor>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
