/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */

export type InventoryMovementsOrderBy =
  (typeof InventoryMovementsOrderBy)[keyof typeof InventoryMovementsOrderBy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InventoryMovementsOrderBy = {
  id: 'id',
  created_at: 'created_at',
  origin_business_entity_id: 'origin_business_entity_id',
  destination_business_entity_id: 'destination_business_entity_id',
} as const;
