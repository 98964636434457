/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  FinanceDocumentEntry,
  GetFinanceDocumentEntriesDefault,
  GetFinanceDocumentEntriesParams,
  PostFinanceDocumentEntriesDefault,
  CreateFinanceDocumentEntryRequest,
  GetFinanceDocumentEntriesIdDefault,
  PutFinanceDocumentEntriesIdDefault,
  UpdateFinanceDocumentEntryRequest,
  DeleteFinanceDocumentEntriesIdDefault,
} from '.././model';
import { useCustomInstance } from '.././mutator/custom-instance';
import type { ErrorType } from '.././mutator/custom-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * List all finance document entries
 */
export const useGetFinanceDocumentEntriesHook = () => {
  const getFinanceDocumentEntries = useCustomInstance<FinanceDocumentEntry[]>();

  return (params?: GetFinanceDocumentEntriesParams, signal?: AbortSignal) => {
    return getFinanceDocumentEntries({
      url: `/finance-document-entries/`,
      method: 'get',
      params,
      signal,
    });
  };
};

export const getGetFinanceDocumentEntriesQueryKey = (params?: GetFinanceDocumentEntriesParams) =>
  [`/finance-document-entries/`, ...(params ? [params] : [])] as const;

export const useGetFinanceDocumentEntriesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentEntriesHook>>>,
  TError = ErrorType<GetFinanceDocumentEntriesDefault>,
>(
  params?: GetFinanceDocumentEntriesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentEntriesHook>>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentEntriesHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFinanceDocumentEntriesQueryKey(params);

  const getFinanceDocumentEntries = useGetFinanceDocumentEntriesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentEntriesHook>>>
  > = ({ signal }) => getFinanceDocumentEntries(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetFinanceDocumentEntriesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentEntriesHook>>>
>;
export type GetFinanceDocumentEntriesQueryError = ErrorType<GetFinanceDocumentEntriesDefault>;

export const useGetFinanceDocumentEntries = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentEntriesHook>>>,
  TError = ErrorType<GetFinanceDocumentEntriesDefault>,
>(
  params?: GetFinanceDocumentEntriesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentEntriesHook>>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetFinanceDocumentEntriesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create a new finance document entry
 */
export const usePostFinanceDocumentEntriesHook = () => {
  const postFinanceDocumentEntries = useCustomInstance<FinanceDocumentEntry>();

  return (createFinanceDocumentEntryRequest: CreateFinanceDocumentEntryRequest) => {
    return postFinanceDocumentEntries({
      url: `/finance-document-entries/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createFinanceDocumentEntryRequest,
    });
  };
};

export const usePostFinanceDocumentEntriesMutationOptions = <
  TError = ErrorType<PostFinanceDocumentEntriesDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostFinanceDocumentEntriesHook>>>,
    TError,
    { data: CreateFinanceDocumentEntryRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostFinanceDocumentEntriesHook>>>,
  TError,
  { data: CreateFinanceDocumentEntryRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const postFinanceDocumentEntries = usePostFinanceDocumentEntriesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostFinanceDocumentEntriesHook>>>,
    { data: CreateFinanceDocumentEntryRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postFinanceDocumentEntries(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostFinanceDocumentEntriesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostFinanceDocumentEntriesHook>>>
>;
export type PostFinanceDocumentEntriesMutationBody = CreateFinanceDocumentEntryRequest;
export type PostFinanceDocumentEntriesMutationError = ErrorType<PostFinanceDocumentEntriesDefault>;

export const usePostFinanceDocumentEntries = <
  TError = ErrorType<PostFinanceDocumentEntriesDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostFinanceDocumentEntriesHook>>>,
    TError,
    { data: CreateFinanceDocumentEntryRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePostFinanceDocumentEntriesMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get a single finance document entry
 */
export const useGetFinanceDocumentEntriesIdHook = () => {
  const getFinanceDocumentEntriesId = useCustomInstance<FinanceDocumentEntry>();

  return (id: string, signal?: AbortSignal) => {
    return getFinanceDocumentEntriesId({
      url: `/finance-document-entries/${id}`,
      method: 'get',
      signal,
    });
  };
};

export const getGetFinanceDocumentEntriesIdQueryKey = (id: string) =>
  [`/finance-document-entries/${id}`] as const;

export const useGetFinanceDocumentEntriesIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentEntriesIdHook>>>,
  TError = ErrorType<GetFinanceDocumentEntriesIdDefault>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentEntriesIdHook>>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentEntriesIdHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFinanceDocumentEntriesIdQueryKey(id);

  const getFinanceDocumentEntriesId = useGetFinanceDocumentEntriesIdHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentEntriesIdHook>>>
  > = ({ signal }) => getFinanceDocumentEntriesId(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetFinanceDocumentEntriesIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentEntriesIdHook>>>
>;
export type GetFinanceDocumentEntriesIdQueryError = ErrorType<GetFinanceDocumentEntriesIdDefault>;

export const useGetFinanceDocumentEntriesId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentEntriesIdHook>>>,
  TError = ErrorType<GetFinanceDocumentEntriesIdDefault>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetFinanceDocumentEntriesIdHook>>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetFinanceDocumentEntriesIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update an finance document entry
 */
export const usePutFinanceDocumentEntriesIdHook = () => {
  const putFinanceDocumentEntriesId = useCustomInstance<FinanceDocumentEntry>();

  return (id: string, updateFinanceDocumentEntryRequest: UpdateFinanceDocumentEntryRequest) => {
    return putFinanceDocumentEntriesId({
      url: `/finance-document-entries/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateFinanceDocumentEntryRequest,
    });
  };
};

export const usePutFinanceDocumentEntriesIdMutationOptions = <
  TError = ErrorType<PutFinanceDocumentEntriesIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutFinanceDocumentEntriesIdHook>>>,
    TError,
    { id: string; data: UpdateFinanceDocumentEntryRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePutFinanceDocumentEntriesIdHook>>>,
  TError,
  { id: string; data: UpdateFinanceDocumentEntryRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const putFinanceDocumentEntriesId = usePutFinanceDocumentEntriesIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePutFinanceDocumentEntriesIdHook>>>,
    { id: string; data: UpdateFinanceDocumentEntryRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return putFinanceDocumentEntriesId(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutFinanceDocumentEntriesIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePutFinanceDocumentEntriesIdHook>>>
>;
export type PutFinanceDocumentEntriesIdMutationBody = UpdateFinanceDocumentEntryRequest;
export type PutFinanceDocumentEntriesIdMutationError =
  ErrorType<PutFinanceDocumentEntriesIdDefault>;

export const usePutFinanceDocumentEntriesId = <
  TError = ErrorType<PutFinanceDocumentEntriesIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutFinanceDocumentEntriesIdHook>>>,
    TError,
    { id: string; data: UpdateFinanceDocumentEntryRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePutFinanceDocumentEntriesIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Delete an finance document entry
 */
export const useDeleteFinanceDocumentEntriesIdHook = () => {
  const deleteFinanceDocumentEntriesId = useCustomInstance<FinanceDocumentEntry>();

  return (id: string) => {
    return deleteFinanceDocumentEntriesId({
      url: `/finance-document-entries/${id}`,
      method: 'delete',
    });
  };
};

export const useDeleteFinanceDocumentEntriesIdMutationOptions = <
  TError = ErrorType<DeleteFinanceDocumentEntriesIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteFinanceDocumentEntriesIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteFinanceDocumentEntriesIdHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteFinanceDocumentEntriesId = useDeleteFinanceDocumentEntriesIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteFinanceDocumentEntriesIdHook>>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteFinanceDocumentEntriesId(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteFinanceDocumentEntriesIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteFinanceDocumentEntriesIdHook>>>
>;

export type DeleteFinanceDocumentEntriesIdMutationError =
  ErrorType<DeleteFinanceDocumentEntriesIdDefault>;

export const useDeleteFinanceDocumentEntriesId = <
  TError = ErrorType<DeleteFinanceDocumentEntriesIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteFinanceDocumentEntriesIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteFinanceDocumentEntriesIdMutationOptions(options);

  return useMutation(mutationOptions);
};
