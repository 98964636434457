import { Route, Routes } from 'react-router-dom';
import { Edit } from './Edit';
import { List } from './List';
import { New } from './New';

export function ItemCategories() {
  return (
    <Routes>
      <Route path="" element={<List />} />
      <Route path=":itemCategoryId" element={<Edit />} />
      <Route path="new" element={<New />} />
    </Routes>
  );
}
