import { z } from 'zod';

export const schema = z.object({
  item_id: z.string().uuid(),

  sku: z.string().nullable().or(z.literal('')),
  barcode: z.string().nullable().or(z.literal('')),

  unit_id: z.string().uuid().nullable().or(z.literal('')),

  supplier_id: z.string().uuid().nullable().or(z.literal('')),
  default_delivery_warehouse_id: z.string().uuid().nullable().or(z.literal('')),
  default_reception_warehouse_id: z.string().uuid().nullable().or(z.literal('')),
});
