import { Avatar, Button } from '@mantine/core';
import { useAuthInfo, useLogoutFunction, useRedirectFunctions } from '@propelauth/react';
import React from 'react';
import { Loading } from '../../components/Loading';

export function Profile() {
  const auth = useAuthInfo();
  const logout = useLogoutFunction();
  const redirectFns = useRedirectFunctions();

  if (auth.loading) {
    return <Loading />;
  }

  if (!auth.user) {
    return <div>User not found</div>;
  }

  return (
    <div>
      <Avatar size="xl" src={auth.user.pictureUrl} />
      <Button
        onClick={() => {
          logout(true).catch(console.error);
        }}
      >
        Logout
      </Button>
      <Button
        onClick={() => {
          redirectFns.redirectToAccountPage();
        }}
      >
        Account
      </Button>
      <Button
        onClick={() => {
          redirectFns.redirectToOrgPage();
        }}
      >
        Org
      </Button>
      <Button
        onClick={() => {
          redirectFns.redirectToCreateOrgPage();
        }}
      >
        New Org
      </Button>
    </div>
  );
}
