/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { InventoryMovementEntriesFilterInventoryItemId } from './inventoryMovementEntriesFilterInventoryItemId';
import type { InventoryMovementEntriesFilterInventoryMovementId } from './inventoryMovementEntriesFilterInventoryMovementId';
import type { InventoryMovementEntriesFilterReference } from './inventoryMovementEntriesFilterReference';

/**
 * The filter to use when searching for inventory movements
 */
export interface InventoryMovementEntriesFilter {
  /** ID of the origin business entity */
  inventory_item_id?: InventoryMovementEntriesFilterInventoryItemId;
  /** ID of the destination business entity */
  inventory_movement_id?: InventoryMovementEntriesFilterInventoryMovementId;
  /** A reference code */
  reference?: InventoryMovementEntriesFilterReference;
}
