import { Button, Group, LoadingOverlay, Modal } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { isNull, omit, omitBy, pipe } from 'lodash/fp';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  UpdateItemCategoryRequest,
  useDeleteItemCategoriesId,
  useGetItemCategoriesId,
  usePutItemCategoriesId,
} from '../../../api';
import { sanitizePayload } from '../../../utils';
import { Form } from './Form';
import { schema } from './schema';

export function Edit() {
  const [showDelete, { open, close }] = useDisclosure(false);
  const navigate = useNavigate();
  const { itemCategoryId } = useParams();

  const updateItemCategoryMutation = usePutItemCategoriesId({
    mutation: {
      onSuccess: () => navigate('..'),
      onError: console.error,
    },
  });

  const deleteItemCategoryMutation = useDeleteItemCategoriesId({
    mutation: {
      onSuccess: () => navigate('..'),
      onError: console.error,
    },
  });

  const handleSubmit = useCallback(
    (values: UpdateItemCategoryRequest) => {
      if (!itemCategoryId) return;

      console.log('values', values);
      updateItemCategoryMutation.mutate({ id: itemCategoryId, data: values });
    },
    [updateItemCategoryMutation.mutate],
  );

  const defaultValues = useMemo<UpdateItemCategoryRequest>(() => ({ name: '', parent_id: '' }), []);

  const form = useForm<UpdateItemCategoryRequest>({
    initialValues: defaultValues,
    validate: zodResolver(schema),
    validateInputOnBlur: true,
    transformValues: sanitizePayload,
  });

  const item = useGetItemCategoriesId(String(itemCategoryId), {
    query: {
      enabled: Boolean(itemCategoryId),
      onSuccess: (itemCategory) => {
        const values = pipe(omit(['id', 'created_at', 'updated_at']), omitBy(isNull))(itemCategory);

        form.setValues(values);
        form.resetDirty();
      },
    },
  });

  if (item.isLoading || !item.data) return <LoadingOverlay visible={true} />;

  return (
    <>
      <Modal opened={showDelete} onClose={close} title="Confirm Delete">
        <p>Are you sure you want to delete {item.data.name}?</p>
        <Button
          variant={'outline'}
          color={'red'}
          onClick={() => {
            if (!itemCategoryId) return;
            deleteItemCategoryMutation.mutate({ id: itemCategoryId });
          }}
        >
          Confirm
        </Button>
      </Modal>

      <h1>Edit {item.data.name}</h1>
      <Form
        onSubmit={handleSubmit}
        onError={console.error}
        loading={updateItemCategoryMutation.isLoading}
        form={form}
      />
      <Group position="right" mt="md">
        <Button variant={'outline'} color={'red'} onClick={open}>
          Delete
        </Button>
      </Group>
    </>
  );
}
