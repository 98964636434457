import { Avatar, Box, Group, Text, UnstyledButton, useMantineTheme } from '@mantine/core';
import { useAuthInfo } from '@propelauth/react';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Loading } from '../Loading';

export function User() {
  const theme = useMantineTheme();
  const auth = useAuthInfo();

  if (auth.loading) {
    return <Loading />;
  }

  if (!auth.user) {
    return <div>User not found</div>;
  }

  return (
    <Link to="/settings">
      <Box
        sx={{
          paddingTop: theme.spacing.sm,
          borderTop: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
          }`,
        }}
      >
        <UnstyledButton
          sx={{
            display: 'block',
            width: '100%',
            padding: theme.spacing.xs,
            borderRadius: theme.radius.sm,
            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

            '&:hover': {
              backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
            },
          }}
        >
          <Group>
            <Avatar src={auth.user.pictureUrl} radius="xl" />
            <Box sx={{ flex: 1 }}>
              <Text size="sm" weight={500}>
                {auth.user.firstName} {auth.user.lastName}
              </Text>
              <Text color="dimmed" size="xs">
                {auth.user.email}
              </Text>
            </Box>

            {theme.dir === 'ltr' ? <IconChevronRight size={18} /> : <IconChevronLeft size={18} />}
          </Group>
        </UnstyledButton>
      </Box>
    </Link>
  );
}
