/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { InventoryItemsOrderBy } from './inventoryItemsOrderBy';
import type { InventoryItemsOrderDirection } from './inventoryItemsOrderDirection';

export type GetInventoryItemsParams = {
  /**
   * The field to order by. Defaults to `id` if not specified.
   */
  order_by?: InventoryItemsOrderBy | null;
  /**
   * The direction to order by. Defaults to `asc` if not specified.
   */
  order_direction?: InventoryItemsOrderDirection | null;
  /**
   * The page number to return. Defaults to 1 if not specified.
   */
  page?: number | null;
  /**
   * The number of items per page. Defaults to 10 if not specified.
   */
  per_page?: number | null;
  /**
   * Barcode of the inventory item
   */
  barcode?: string | null;
  /**
   * Identifier of the business entity that is the default delivery warehouse of this inventory item
   */
  default_delivery_warehouse_id?: string | null;
  /**
   * Identifier of the business entity that is the default reception warehouse of this inventory item
   */
  default_reception_warehouse_id?: string | null;
  /**
   * Identifier of the item this inventory item is for
   */
  item_id?: string | null;
  /**
   * Stock keeping unit of the inventory item
   */
  sku?: string | null;
  /**
   * Identifier of the business entity that is the supplier of this inventory item
   */
  supplier_id?: string | null;
  /**
   * Identifier of the unit this inventory item is for
   */
  unit_id?: string | null;
};
