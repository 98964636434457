/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { ItemCategoriesOrderBy } from './itemCategoriesOrderBy';
import type { ItemCategoriesOrderDirection } from './itemCategoriesOrderDirection';

export type GetItemCategoriesParams = {
  /**
   * The field to order by. Defaults to `id` if not specified.
   */
  order_by?: ItemCategoriesOrderBy | null;
  /**
   * The direction to order by. Defaults to `asc` if not specified.
   */
  order_direction?: ItemCategoriesOrderDirection | null;
  /**
   * The page number to return. Defaults to 1 if not specified.
   */
  page?: number | null;
  /**
   * The number of items per page. Defaults to 10 if not specified.
   */
  per_page?: number | null;
  /**
   * The name of the item_category to search for
   */
  name?: string | null;
  /**
   * The parent id of the item category
   */
  parent_id?: string | null;
};
