/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { InventoryItemsFilterBarcode } from './inventoryItemsFilterBarcode';
import type { InventoryItemsFilterDefaultDeliveryWarehouseId } from './inventoryItemsFilterDefaultDeliveryWarehouseId';
import type { InventoryItemsFilterDefaultReceptionWarehouseId } from './inventoryItemsFilterDefaultReceptionWarehouseId';
import type { InventoryItemsFilterItemId } from './inventoryItemsFilterItemId';
import type { InventoryItemsFilterSku } from './inventoryItemsFilterSku';
import type { InventoryItemsFilterSupplierId } from './inventoryItemsFilterSupplierId';
import type { InventoryItemsFilterUnitId } from './inventoryItemsFilterUnitId';

/**
 * The filter to use when searching for inventory items
 */
export interface InventoryItemsFilter {
  /** Barcode of the inventory item */
  barcode?: InventoryItemsFilterBarcode;
  /** Identifier of the business entity that is the default delivery warehouse of this inventory item */
  default_delivery_warehouse_id?: InventoryItemsFilterDefaultDeliveryWarehouseId;
  /** Identifier of the business entity that is the default reception warehouse of this inventory item */
  default_reception_warehouse_id?: InventoryItemsFilterDefaultReceptionWarehouseId;
  /** Identifier of the item this inventory item is for */
  item_id?: InventoryItemsFilterItemId;
  /** Stock keeping unit of the inventory item */
  sku?: InventoryItemsFilterSku;
  /** Identifier of the business entity that is the supplier of this inventory item */
  supplier_id?: InventoryItemsFilterSupplierId;
  /** Identifier of the unit this inventory item is for */
  unit_id?: InventoryItemsFilterUnitId;
}
