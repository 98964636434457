/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { FinanceDocumentTotalAmount } from './financeDocumentTotalAmount';

export interface FinanceDocument {
  business_entity_id: string;
  /** Timestamp of when the inventory item was created */
  created_at: string;
  date: string;
  document_type: string;
  id: string;
  status: string;
  total_amount?: FinanceDocumentTotalAmount;
  /** Timestamp of when the inventory item was last updated */
  updated_at: string;
}
