/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */

export type InventoryMovementEntriesOrderBy =
  (typeof InventoryMovementEntriesOrderBy)[keyof typeof InventoryMovementEntriesOrderBy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InventoryMovementEntriesOrderBy = {
  id: 'id',
  created_at: 'created_at',
  inventory_item_id: 'inventory_item_id',
  inventory_movement_id: 'inventory_movement_id',
} as const;
