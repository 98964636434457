/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */

export type InventoryLevelsOrderBy =
  (typeof InventoryLevelsOrderBy)[keyof typeof InventoryLevelsOrderBy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InventoryLevelsOrderBy = {
  id: 'id',
  created_at: 'created_at',
  warehouse_id: 'warehouse_id',
  inventory_item_id: 'inventory_item_id',
} as const;
