/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import type { UpdateInventoryMovementRequestConcept } from './updateInventoryMovementRequestConcept';
import type { UpdateInventoryMovementRequestConditions } from './updateInventoryMovementRequestConditions';
import type { UpdateInventoryMovementRequestDescription } from './updateInventoryMovementRequestDescription';
import type { UpdateInventoryMovementRequestInsurance } from './updateInventoryMovementRequestInsurance';
import type { UpdateInventoryMovementRequestReference } from './updateInventoryMovementRequestReference';
import type { UpdateInventoryMovementRequestTerms } from './updateInventoryMovementRequestTerms';
import type { UpdateInventoryMovementRequestTransport } from './updateInventoryMovementRequestTransport';

export interface UpdateInventoryMovementRequest {
  /** A concept */
  concept?: UpdateInventoryMovementRequestConcept;
  /** Delivery conditions */
  conditions?: UpdateInventoryMovementRequestConditions;
  /** A short description */
  description?: UpdateInventoryMovementRequestDescription;
  /** ID of the destination business entity */
  destination_business_entity_id: string;
  /** The insurance details */
  insurance?: UpdateInventoryMovementRequestInsurance;
  /** ID of the origin business entity */
  origin_business_entity_id: string;
  /** A reference code */
  reference?: UpdateInventoryMovementRequestReference;
  /** Delivery terms */
  terms?: UpdateInventoryMovementRequestTerms;
  /** The transport details */
  transport?: UpdateInventoryMovementRequestTransport;
}
