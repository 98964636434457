/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inventory Management System API Docs
 * # IMS

Some API for the future of Inventory Management Systems.

 * OpenAPI spec version: 0.1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';
import type {
  BusinessEntity,
  GetBusinessEntitiesDefault,
  GetBusinessEntitiesParams,
  PostBusinessEntitiesDefault,
  CreateBusinessEntityRequest,
  GetBusinessEntitiesIdDefault,
  PutBusinessEntitiesIdDefault,
  UpdateBusinessEntityRequest,
  DeleteBusinessEntitiesIdDefault,
} from '.././model';
import { useCustomInstance } from '.././mutator/custom-instance';
import type { ErrorType } from '.././mutator/custom-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * List all business entities
 */
export const useGetBusinessEntitiesHook = () => {
  const getBusinessEntities = useCustomInstance<BusinessEntity[]>();

  return (params?: GetBusinessEntitiesParams, signal?: AbortSignal) => {
    return getBusinessEntities({ url: `/business-entities/`, method: 'get', params, signal });
  };
};

export const getGetBusinessEntitiesQueryKey = (params?: GetBusinessEntitiesParams) =>
  [`/business-entities/`, ...(params ? [params] : [])] as const;

export const useGetBusinessEntitiesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBusinessEntitiesHook>>>,
  TError = ErrorType<GetBusinessEntitiesDefault>,
>(
  params?: GetBusinessEntitiesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetBusinessEntitiesHook>>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetBusinessEntitiesHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBusinessEntitiesQueryKey(params);

  const getBusinessEntities = useGetBusinessEntitiesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetBusinessEntitiesHook>>>
  > = ({ signal }) => getBusinessEntities(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetBusinessEntitiesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetBusinessEntitiesHook>>>
>;
export type GetBusinessEntitiesQueryError = ErrorType<GetBusinessEntitiesDefault>;

export const useGetBusinessEntities = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBusinessEntitiesHook>>>,
  TError = ErrorType<GetBusinessEntitiesDefault>,
>(
  params?: GetBusinessEntitiesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetBusinessEntitiesHook>>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBusinessEntitiesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create a new business entity
 */
export const usePostBusinessEntitiesHook = () => {
  const postBusinessEntities = useCustomInstance<BusinessEntity>();

  return (createBusinessEntityRequest: CreateBusinessEntityRequest) => {
    return postBusinessEntities({
      url: `/business-entities/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createBusinessEntityRequest,
    });
  };
};

export const usePostBusinessEntitiesMutationOptions = <
  TError = ErrorType<PostBusinessEntitiesDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostBusinessEntitiesHook>>>,
    TError,
    { data: CreateBusinessEntityRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostBusinessEntitiesHook>>>,
  TError,
  { data: CreateBusinessEntityRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const postBusinessEntities = usePostBusinessEntitiesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostBusinessEntitiesHook>>>,
    { data: CreateBusinessEntityRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postBusinessEntities(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostBusinessEntitiesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostBusinessEntitiesHook>>>
>;
export type PostBusinessEntitiesMutationBody = CreateBusinessEntityRequest;
export type PostBusinessEntitiesMutationError = ErrorType<PostBusinessEntitiesDefault>;

export const usePostBusinessEntities = <
  TError = ErrorType<PostBusinessEntitiesDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostBusinessEntitiesHook>>>,
    TError,
    { data: CreateBusinessEntityRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePostBusinessEntitiesMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get a single business entity
 */
export const useGetBusinessEntitiesIdHook = () => {
  const getBusinessEntitiesId = useCustomInstance<BusinessEntity>();

  return (id: string, signal?: AbortSignal) => {
    return getBusinessEntitiesId({ url: `/business-entities/${id}`, method: 'get', signal });
  };
};

export const getGetBusinessEntitiesIdQueryKey = (id: string) =>
  [`/business-entities/${id}`] as const;

export const useGetBusinessEntitiesIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBusinessEntitiesIdHook>>>,
  TError = ErrorType<GetBusinessEntitiesIdDefault>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetBusinessEntitiesIdHook>>>,
      TError,
      TData
    >;
  },
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetBusinessEntitiesIdHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBusinessEntitiesIdQueryKey(id);

  const getBusinessEntitiesId = useGetBusinessEntitiesIdHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetBusinessEntitiesIdHook>>>
  > = ({ signal }) => getBusinessEntitiesId(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetBusinessEntitiesIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetBusinessEntitiesIdHook>>>
>;
export type GetBusinessEntitiesIdQueryError = ErrorType<GetBusinessEntitiesIdDefault>;

export const useGetBusinessEntitiesId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBusinessEntitiesIdHook>>>,
  TError = ErrorType<GetBusinessEntitiesIdDefault>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetBusinessEntitiesIdHook>>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBusinessEntitiesIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update an business entity
 */
export const usePutBusinessEntitiesIdHook = () => {
  const putBusinessEntitiesId = useCustomInstance<BusinessEntity>();

  return (id: string, updateBusinessEntityRequest: UpdateBusinessEntityRequest) => {
    return putBusinessEntitiesId({
      url: `/business-entities/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateBusinessEntityRequest,
    });
  };
};

export const usePutBusinessEntitiesIdMutationOptions = <
  TError = ErrorType<PutBusinessEntitiesIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutBusinessEntitiesIdHook>>>,
    TError,
    { id: string; data: UpdateBusinessEntityRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePutBusinessEntitiesIdHook>>>,
  TError,
  { id: string; data: UpdateBusinessEntityRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const putBusinessEntitiesId = usePutBusinessEntitiesIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePutBusinessEntitiesIdHook>>>,
    { id: string; data: UpdateBusinessEntityRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return putBusinessEntitiesId(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutBusinessEntitiesIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePutBusinessEntitiesIdHook>>>
>;
export type PutBusinessEntitiesIdMutationBody = UpdateBusinessEntityRequest;
export type PutBusinessEntitiesIdMutationError = ErrorType<PutBusinessEntitiesIdDefault>;

export const usePutBusinessEntitiesId = <
  TError = ErrorType<PutBusinessEntitiesIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutBusinessEntitiesIdHook>>>,
    TError,
    { id: string; data: UpdateBusinessEntityRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePutBusinessEntitiesIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Delete an business entity
 */
export const useDeleteBusinessEntitiesIdHook = () => {
  const deleteBusinessEntitiesId = useCustomInstance<BusinessEntity>();

  return (id: string) => {
    return deleteBusinessEntitiesId({ url: `/business-entities/${id}`, method: 'delete' });
  };
};

export const useDeleteBusinessEntitiesIdMutationOptions = <
  TError = ErrorType<DeleteBusinessEntitiesIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteBusinessEntitiesIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteBusinessEntitiesIdHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteBusinessEntitiesId = useDeleteBusinessEntitiesIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteBusinessEntitiesIdHook>>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteBusinessEntitiesId(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteBusinessEntitiesIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteBusinessEntitiesIdHook>>>
>;

export type DeleteBusinessEntitiesIdMutationError = ErrorType<DeleteBusinessEntitiesIdDefault>;

export const useDeleteBusinessEntitiesId = <
  TError = ErrorType<DeleteBusinessEntitiesIdDefault>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteBusinessEntitiesIdHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteBusinessEntitiesIdMutationOptions(options);

  return useMutation(mutationOptions);
};
